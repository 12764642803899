import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import pic1 from "./../../../images/profile/small/pic1.jpg";
import Editable from "../Editable";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";

const Gensen = () => {
  const history = useHistory();
  const routeChange = () => {
    let path = `/shinryu-userdetail`;
    history.push(path);
  };

  return (
    <>
      <PageTitle activeMenu="源泉徴収票" motherMenu="shinryu" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Table bordered className="meisai">
                <tbody>
                  <tr>
                    <td colspan="2" rowspan="5">
                      支払いを受ける者
                    </td>
                    <td rowspan="5">住所又は居所</td>
                    <td colspan="8" rowspan="5">
                      〒665-0821 宝塚市安倉北２-１１-１
                    </td>
                    <td colspan="5">(受給者番号)</td>
                    <td colspan="7">2301 0007</td>
                  </tr>
                  <tr>
                    <td colspan="5">(個人番号)</td>
                    <td colspan="7"></td>
                  </tr>
                  <tr>
                    <td colspan="5">(役職名)</td>
                    <td colspan="7"></td>
                  </tr>
                  <tr>
                    <td rowspan="2">氏名</td>
                    <td colspan="11">(フリガナ)ﾔﾏﾀﾞ ｼﾝｿﾞｳ</td>
                  </tr>
                  <tr>
                    <td colspan="11">山田　伸三</td>
                  </tr>
                  <tr>
                    <td colspan="5">種別</td>
                    <td colspan="4">支払金額</td>
                    <td colspan="4">給与所得控除後の金額 （調整控除後）</td>
                    <td colspan="6">所得控除の額の合計額</td>
                    <td colspan="4">源泉徴収税額</td>
                  </tr>
                  <tr>
                    <td colspan="5">給料・賞与</td>
                    <td colspan="4">2237950</td>
                    <td colspan="4">1485200</td>
                    <td colspan="6">1078073</td>
                    <td colspan="4">20700</td>
                  </tr>
                  <tr>
                    <td colspan="4" rowspan="2">
                      （源泉）控除対象配偶者の有無等
                    </td>
                    <td colspan="5" rowspan="2">
                      配偶者(特別) 控除の額
                    </td>
                    <td colspan="9">控除対象扶養親族の数 (配偶者を除く。)</td>
                    <td rowspan="2">16歳未満扶養親族の数</td>
                    <td colspan="3">障害者の数 (本人を除く。)</td>
                    <td rowspan="2">非居住者である親族の数</td>
                  </tr>
                  <tr>
                    <td colspan="2">特定</td>
                    <td colspan="3">老人</td>
                    <td colspan="4">その他</td>
                    <td colspan="2">特別</td>
                    <td>その他</td>
                  </tr>
                  <tr>
                    <td colspan="2">有</td>
                    <td>従有</td>
                    <td>老人</td>
                    <td colspan="5" rowspan="2">
                      480000円
                    </td>
                    <td className="smallt">人</td>
                    <td className="smallt">従人</td>
                    <td className="smallt">従人</td>
                    <td className="smallt">人</td>
                    <td className="smallt">従人</td>
                    <td className="smallt">人</td>
                    <td></td>
                    <td></td>
                    <td className="smallt">従人</td>
                    <td className="smallt">人</td>
                    <td className="smallt">従人</td>
                    <td className="smallt">人</td>
                    <td className="smallt">人</td>
                    <td className="smallt">人</td>
                  </tr>
                  <tr>
                    <td colspan="2">〇</td>
                    <td></td>
                    <td>〇</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="6">社会保険料等の金額</td>
                    <td colspan="5">生命保険料の控除額</td>
                    <td colspan="7">地震保険料の控除額</td>
                    <td colspan="5">住宅借入金等特別控除額</td>
                  </tr>
                  <tr>
                    <td colspan="5" className="smallt">
                      内
                    </td>
                    <td className="smallt">円</td>
                    <td colspan="5" className="smallt">
                      円
                    </td>
                    <td colspan="7" className="smallt">
                      円
                    </td>
                    <td colspan="5" className="smallt">
                      円
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6">76611</td>
                    <td colspan="5">41462</td>
                    <td colspan="7">0</td>
                    <td colspan="5">0</td>
                  </tr>
                  <tr>
                    <td colspan="23">(摘要)</td>
                  </tr>
                  <tr>
                    <td colspan="2">生命保険料の金額の内訳</td>
                    <td>新生命保険料の金額</td>
                    <td colspan="3">20088円</td>
                    <td>旧生命保険料の金額</td>
                    <td colspan="3"></td>
                    <td>介護医療保険料の金額</td>
                    <td colspan="3">22836円</td>
                    <td colspan="3">新個人年金保険料の金額</td>
                    <td colspan="3"></td>
                    <td>旧個人年金保険料の金額</td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2" rowspan="4">
                      住宅借入金等特別控除の額の内訳
                    </td>
                    <td rowspan="2">住宅借入金等特別控除適用数</td>
                    <td colspan="3" rowspan="2"></td>
                    <td rowspan="2">居住開始年月日(1回目)</td>
                    <td>年</td>
                    <td>月</td>
                    <td>日</td>
                    <td colspan="2" rowspan="2">
                      住宅借入金等特別控除区分（1回目）
                    </td>
                    <td colspan="2" rowspan="2"></td>
                    <td colspan="5" rowspan="2">
                      住宅借入金等年末残高(1回目)
                    </td>
                    <td colspan="4" className="smallt">
                      円
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                  </tr>
                  <tr>
                    <td rowspan="2">住宅借入金等特別控除可能額</td>
                    <td colspan="3" className="smallt">
                      円
                    </td>
                    <td rowspan="2">居住開始年月日(2回目)</td>
                    <td>年</td>
                    <td>月</td>
                    <td>日</td>
                    <td colspan="2" rowspan="2">
                      住宅借入金等特別控除区分（2回目）
                    </td>
                    <td colspan="2" rowspan="2"></td>
                    <td colspan="5" rowspan="2">
                      住宅借入金等年末残高(2回目)
                    </td>
                    <td colspan="4" className="smallt">
                      円
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4"></td>
                  </tr>
                  <tr>
                    <td colspan="2" rowspan="4">
                      (源泉・特別) 控除対象配偶者
                    </td>
                    <td>(フリガナ)</td>
                    <td colspan="9">ﾔﾏﾀﾞ ﾁﾖｺ</td>
                    <td rowspan="3">区分</td>
                    <td colspan="2" rowspan="3"></td>
                    <td colspan="3" rowspan="4">
                      配偶者の合計所得
                    </td>
                    <td className="smallt">円</td>
                    <td rowspan="2">国民年金保険料等の金額</td>
                    <td className="smallt">円</td>
                    <td rowspan="2">旧長期損害保険料の金額</td>
                    <td className="smallt">円</td>
                  </tr>
                  <tr>
                    <td rowspan="2">氏名</td>
                    <td colspan="9" rowspan="2">
                      山田 千代子
                    </td>
                    <td rowspan="3"></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowspan="2">基礎控除の額</td>
                    <td className="smallt">円</td>
                    <td rowspan="2">所得金額調整控除額</td>
                    <td className="smallt">円</td>
                  </tr>
                  <tr>
                    <td>個人番号</td>
                    <td colspan="12">************</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowspan="12">控除対象扶養親族</td>
                    <td rowspan="3">1</td>
                    <td>(フリガナ)</td>
                    <td colspan="9"></td>
                    <td rowspan="2">区分</td>
                    <td colspan="2" rowspan="2"></td>
                    <td rowspan="12">16歳未満の扶養親族</td>
                    <td rowspan="3">1</td>
                    <td>(フリガナ)</td>
                    <td></td>
                    <td></td>
                    <td rowspan="2">区分</td>
                    <td rowspan="2"></td>
                    <td rowspan="8">(備考)</td>
                  </tr>
                  <tr>
                    <td>氏名</td>
                    <td colspan="9"></td>
                    <td>氏名</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>個人番号</td>
                    <td colspan="12"></td>
                    <td colspan="5"></td>
                  </tr>
                  <tr>
                    <td rowspan="3">2</td>
                    <td>(フリガナ)</td>
                    <td colspan="9"></td>
                    <td rowspan="2">区分</td>
                    <td colspan="2" rowspan="2"></td>
                    <td rowspan="3">2</td>
                    <td>(フリガナ)</td>
                    <td></td>
                    <td></td>
                    <td rowspan="2">区分</td>
                    <td rowspan="2"></td>
                  </tr>
                  <tr>
                    <td>氏名</td>
                    <td colspan="9"></td>
                    <td>氏名</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>個人番号</td>
                    <td colspan="12"></td>
                    <td colspan="5"></td>
                  </tr>
                  <tr>
                    <td rowspan="3">3</td>
                    <td>(フリガナ)</td>
                    <td colspan="9"></td>
                    <td rowspan="2">区分</td>
                    <td colspan="2" rowspan="2"></td>
                    <td rowspan="3">3</td>
                    <td>(フリガナ)</td>
                    <td></td>
                    <td></td>
                    <td rowspan="2">区分</td>
                    <td rowspan="2"></td>
                  </tr>
                  <tr>
                    <td>氏名</td>
                    <td colspan="9"></td>
                    <td>氏名</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>個人番号</td>
                    <td colspan="12"></td>
                    <td colspan="5"></td>
                    <td rowspan="4"></td>
                  </tr>
                  <tr>
                    <td rowspan="3">4</td>
                    <td>(フリガナ)</td>
                    <td colspan="9"></td>
                    <td rowspan="2">区分</td>
                    <td colspan="2" rowspan="2"></td>
                    <td rowspan="3">4</td>
                    <td>(フリガナ)</td>
                    <td></td>
                    <td></td>
                    <td rowspan="2">区分</td>
                    <td rowspan="2"></td>
                  </tr>
                  <tr>
                    <td>氏名</td>
                    <td colspan="9"></td>
                    <td>氏名</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>個人番号</td>
                    <td colspan="12"></td>
                    <td colspan="5"></td>
                  </tr>
                  <tr>
                    <td rowspan="2">未成年者</td>
                    <td rowspan="2">外国人</td>
                    <td rowspan="2">死亡退職</td>
                    <td rowspan="2">災害者</td>
                    <td rowspan="2">乙欄</td>
                    <td colspan="2">本人が障害者</td>
                    <td rowspan="2">寡婦</td>
                    <td rowspan="2">ひとり親</td>
                    <td rowspan="2">勤労学生</td>
                    <td rowspan="3"></td>
                    <td colspan="6">中途就・退職</td>
                    <td colspan="6">受給者生年月日</td>
                  </tr>
                  <tr>
                    <td style={{ width: "60px" }}>特別</td>
                    <td style={{ width: "60px" }}>その他</td>
                    <td>就職</td>
                    <td colspan="2">退職</td>
                    <td>年</td>
                    <td>月</td>
                    <td>日</td>
                    <td colspan="3">元号</td>
                    <td>年</td>
                    <td>月</td>
                    <td>日</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td>4</td>
                    <td></td>
                    <td></td>
                    <td colspan="3">昭和</td>
                    <td>22</td>
                    <td>8</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td colspan="2" rowspan="3">
                      支払者
                    </td>
                    <td colspan="3">個人番号又は 法人番号</td>
                    <td colspan="18">8140001081613</td>
                  </tr>
                  <tr>
                    <td colspan="3">住所(居所) 又は所在地</td>
                    <td colspan="18">〒665-0822 宝塚市安倉中２丁目４番８号</td>
                  </tr>
                  <tr>
                    <td colspan="3">氏名又は名称</td>
                    <td colspan="11">株 式 会 社　シ　ン　リ　ュ　ウ</td>
                    <td colspan="7">（電話） 0797-86-8441</td>
                  </tr>
                  <tr>
                    <td colspan="2">署番号</td>
                    <td colspan="3">003311</td>
                    <td colspan="5">整理番号</td>
                    <td colspan="13">00228281</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          {/* <!-- /# card --> */}
        </Col>
      </Row>
    </>
  );
};
export default Gensen;
