import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import pic1 from "./../../../images/profile/small/pic1.jpg";
import Editable from "../Editable";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";

const Kyuyomeisai = () => {
  const history = useHistory();
  const routeChange = () => {
    let path = `/shinryu-userdetail`;
    history.push(path);
  };

  return (
    <>
      <PageTitle activeMenu="給与明細" motherMenu="shinryu" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Table bordered className="meisai" style={{ width: "30%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontSize: "16px" }} className="p-5">
                      〒 665-0821<br></br>
                      宝塚市安倉北２-１１-１<br></br>
                      <br></br>
                      山田　伸三 殿
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="mb-2 text-right">
                [8-1400-0108-1613]<br></br>00009608　株 式 会
                社　シ　ン　リ　ュ　ウ
              </div>
              <Table bordered className="meisai">
                <tbody>
                  <tr>
                    <td rowspan="5" style={{ width: "200px" }}>
                      <h3>給与明細書</h3>
                      <br></br>5年 1月度（給与）<br></br> 支給日 5年1月10日
                    </td>
                    <td style={{ width: "100px" }}>出勤日数</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style={{ width: "100px" }}>24</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
              <Table bordered className="meisai">
                <tbody>
                  <tr>
                    <td rowspan="8" style={{ width: "100px" }}>
                      <h4>支　　　給</h4>
                    </td>
                    <td style={{ width: "120px" }}>基本給</td>
                    <td style={{ width: "120px" }}>月　給</td>
                    <td style={{ width: "120px" }}>交通費</td>
                    <td style={{ width: "120px" }}>資格手当</td>
                    <td style={{ width: "120px" }}>現場手当</td>
                    <td style={{ width: "120px" }}>前月差額分</td>
                    <td style={{ width: "120px" }}>通信費</td>
                  </tr>
                  <tr>
                    <td>181825</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>4000</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>立替え金</td>
                    <td>前月交通費</td>
                    <td>休日出勤</td>
                    <td>時間外</td>
                    <td>その他手当</td>
                    <td>教育手当て</td>
                    <td>教育交通費</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>業務手当</td>
                    <td>役職手当</td>
                    <td>深夜手当</td>
                    <td>時間外深夜</td>
                    <td>休日出勤深夜</td>
                    <td>職務残業①</td>
                    <td>職務残業②</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>欠勤控除</td>
                    <td>休業手当</td>
                    <td>みなし残業</td>
                    <td>遠方出張手当</td>
                    <td>業務講習手当</td>
                    <td>その他手当２</td>
                    <td>総支給額</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>185825</td>
                  </tr>
                </tbody>
              </Table>
              <Table bordered className="meisai">
                <tbody>
                  <tr>
                    <td rowspan="8" style={{ width: "100px" }}>
                      <h4>控　　　除</h4>
                    </td>
                    <td style={{ width: "120px" }}>健康保険料</td>
                    <td style={{ width: "120px" }}>厚生年金保険料</td>
                    <td style={{ width: "120px" }}>その他社会保険料</td>
                    <td style={{ width: "120px" }}>雇用保険料</td>
                    <td style={{ width: "120px" }}>課税対象額</td>
                    <td style={{ width: "120px" }}>所得税</td>
                    <td style={{ width: "120px" }}>住民税</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>929</td>
                    <td>184896</td>
                    <td>2570</td>
                    <td>1300</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>その他諸控除</td>
                    <td>社宅控除</td>
                    <td>控除−３</td>
                    <td>控除−４</td>
                    <td>支給済み分</td>
                    <td>控除−６</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>控除−７</td>
                    <td>控除−８</td>
                    <td>控除−９</td>
                    <td>控除−１０</td>
                    <td>控除−１１</td>
                    <td>控除−１２</td>
                    <td>控除−１３</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>控除−１４</td>
                    <td>控除−１５</td>
                    <td>控除合計</td>
                    <td></td>
                    <td>前月端額分</td>
                    <td>当月端額</td>
                    <td>差引支給額</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>4799</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>181026</td>
                  </tr>
                </tbody>
              </Table>
              <Table bordered className="meisai">
                <tbody>
                  <tr>
                    <td style={{ width: "150px" }}>端</td>
                    <td style={{ width: "150px" }}>雇用率</td>
                    <td style={{ width: "150px" }}>退職者</td>
                    <td style={{ width: "150px" }}>非課税</td>
                    <td style={{ width: "150px" }}>課税対象額累計</td>
                    <td style={{ width: "150px" }}>課税支給累計</td>
                    <td style={{ width: "150px" }}>社会保険累計</td>
                    <td style={{ width: "150px" }}>所得税累計</td>
                    <td rowspan="2" style={{ width: "100px" }}>
                      受領印
                    </td>
                    <td rowspan="2" style={{ width: "100px" }}></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>5.00</td>
                    <td></td>
                    <td>無</td>
                    <td>184896</td>
                    <td>185825</td>
                    <td>929</td>
                    <td>2570</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          {/* <!-- /# card --> */}
        </Col>
      </Row>
    </>
  );
};
export default Kyuyomeisai;
