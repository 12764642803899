import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { loadingToggleAction } from "../../store/actions/AuthActions";
//
import { useRecoilState } from "recoil";
import loginbg from "../../images/bg-login3.jpg";
import logo from "../../images/logo.png";
import { signInService } from "../../services/ShinryuService";
import { userIdState, userIsLoginState } from "../../store/recoil";

function Login(props) {
  const [userUniqueId, setUserUniqueId] = useState("123");
  let errorsObj = { userUniqueId: "", userPassword: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [userPassword, setUserPassword] = useState("123");
  const [userIsLogin, setUserIsLogin] = useRecoilState(userIsLoginState);
  const [userId, setUserId] = useRecoilState(userIdState);

  const dispatch = useDispatch();

  const onLogin = async (e) => {
    const result = await signInService(userUniqueId, userPassword);

    if (result) {
      localStorage.setItem("userId", result.userId); // save userId to localStorage
      localStorage.setItem("uuN", result.uuN); // save userId to localStorage
      setUserId(result.userId);
      dispatch(loadingToggleAction(true));
      setUserIsLogin(true);
      props.history.push("/shinryu-userlist");
    }
  };

  return (
    <div
      className="login-main-page h-100"
      style={{ backgroundImage: "url(" + loginbg + ")" }}>
      {/* <div className="login-aside-left" style={{backgroundImage:"url("+ login +")"}}>
                    <Link to="/dashboard" className="login-logo">
                        <img src={logo} alt="" className="mr-2"/>
                        <img src={logotext} alt="" className="ml-1"/>
                      </Link>
                    <div className="login-description">
                        <h2 className="text-black  mb-2">Check the Status</h2>
                        <p className="fs-12">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
                        <ul className="social-icons mt-4">
                            <li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
                            <li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
                            <li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
                        </ul>
                        <div className="mt-5">
                            <Link to={"#"} className="text-black mr-4">Privacy Policy</Link>
                            <Link to={"#"} className="text-black mr-4">Contact</Link>
                            <Link to={"#"} className="text-black">© 2021 Shinryu</Link>
                        </div>
                    </div>
                </div> */}
      <Row className="justify-content-center d-flex align-items-center h-100">
        <Col xl="3">
          <Card>
            <Card.Header className=" border-0 pb-0 mx-auto">
              <img src={logo} width="200px" />
            </Card.Header>
            <Card.Body>
              {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}
              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}
              {/* <form onSubmit={onLogin}> */}
              <div className="form-group">
                <label className="mb-2 ">
                  <strong>User ID</strong>
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={userUniqueId}
                  onChange={(e) => setUserUniqueId(e.target.value)}
                />
                {errors.email && (
                  <div className="text-danger fs-12">{errors.userUniqueId}</div>
                )}
              </div>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong>Password</strong>
                </label>
                <input
                  type="password"
                  className="form-control"
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                />
                {errors.password && (
                  <div className="text-danger fs-12">{errors.userPassword}</div>
                )}
              </div>
              <div className="form-row d-flex justify-content-between mt-4 mb-2">
                <div className="form-group">
                  <div className="custom-control custom-checkbox ml-1 ">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="basic_checkbox_1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="basic_checkbox_1 ">
                      ログイン情報を保存する
                    </label>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  onClick={onLogin}
                  type="submit"
                  className="btn-primary p-3 rounded btn-block">
                  ログイン
                </button>
              </div>
              {/* </form> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
