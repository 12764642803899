import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  sendNotificationScheduler,
  sendNotificationStub,
} from "../../../services/ShinryuService";
import PageTitle from "../../layouts/PageTitle";

const SendPay = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [subType01, setSubtype01] = useState("給与明細");
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const handleUpload = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const fileList = Array.from(files);
    setFiles(fileList);
    setFileName(fileList[0].name);
    // await sendNotificationInform(fileList)
  };
  const handleSubmitUpload = async (e) => {
    e.preventDefault();
    const isPayStub = subType01 === "給与明細";
    const isWorkDetail = subType01 === "勤務明細";
    const fileNameIncludesPayStub = fileName.includes("給与明細");
    const fileNameIncludesWorkDetail = fileName.includes("勤務明細");

    // if (
    //   (isPayStub && !fileNameIncludesPayStub) ||
    //   (isWorkDetail && !fileNameIncludesWorkDetail)
    // ) {
    //   alert("ファイル名に適切な明細書の種類が含まれていません");
    //   setFiles([]);
    //   setFileName("");
    //   return;
    // }

    if (title === "" && content === "-") {
      alert("明細書の種類を選択してください");
      return;
    }

    if (files.length === 0) {
      alert("ファイルを選択してください");
      return;
    }

    if (subType01 === "給与明細") {
      await sendNotificationStub(files, subType01, title, content);
    } else if (subType01 === "勤務明細") {
      await sendNotificationScheduler(files, subType01, title, content);
    }
  };

  const handleRadioChange = (e) => {
    setSubtype01(e.target.value);
  };
  useEffect(() => {}, [subType01]);
  return (
    <>
      <PageTitle activeMenu="給与明細" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div>
              <div className="settings-form">
                <form onSubmit={handleSubmitUpload}>
                  <div className="form-group mb-3">
                    <div className="basic-form">
                      <div className="form-group mb-0">
                        <label className="radio-inline mr-3">
                          <input
                            type="radio"
                            name="optradio"
                            value="給与明細"
                            checked={subType01 === "給与明細"}
                            onChange={handleRadioChange}
                          />{" "}
                          給与明細
                        </label>
                        <label className="radio-inline mr-3">
                          <input
                            type="radio"
                            name="optradio"
                            value="勤務明細"
                            checked={subType01 === "勤務明細"}
                            onChange={handleRadioChange}
                          />{" "}
                          勤務明細
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="basic-form custom_file_input">
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            onChange={handleUpload}
                          />
                          <label className="custom-file-label">
                            {fileName ? fileName.substr(-60) : "ファイル選択"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      placeholder="件名"
                      className="form-control"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <div className="basic-form">
                      <div className="form-group">
                        <textarea
                          className="form-control2"
                          rows="7"
                          style={{ height: "120px !important" }}
                          value={content}
                          onChange={(e) => setContent(e.target.value)}
                          placeholder="メッセージ本文"
                          id="comment"></textarea>
                      </div>
                    </div>
                  </div>

                  <Button
                    className="btn btn-primary mr-3 rounded btn-sm"
                    type="submit">
                    一括送信
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SendPay;
