import axios from "axios";
import {
  getLocalStorage,
  removeLocalStorage,
  setAdminInfo,
  setLocalStorage,
} from "../store/recoil";
// const HOST =
//   "http://localhost";
const HOST = "https://api.shinryu.works";

export function signInService(userUniqueId, userPassword) {
  //Controll this!
  const postData = {
    userUniqueId,
    userPassword,
  };
  return axios
    .post(
      `${HOST}/user/signIn`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        const userToken = res.data.data.token;
        setLocalStorage("userToken", userToken);
        setAdminInfo("admininfo", res.data.data.userDetail.authority);
        return {
          userId: res.data.data.userDetail.userName, // assuming the response contains 'userId',
          uuN: res.data.data.userDetail.id,
          success: true,
        };
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function getUserListFilterListService() {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
  };
  return axios
    .post(
      `${HOST}/user/list/filter`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function getUserListService(query) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    query,
  };
  return axios
    .post(
      `${HOST}/user/search/query`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function getUserDetailService(d) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    id: d,
    type: "admin",
  };
  return axios
    .post(
      `${HOST}/user/detail`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function getUserCsvService(admin) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    // id: admin.id,
    type: "admin",
  };
  return axios
    .post(
      `${HOST}/user/list`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function getUserUpdateService(formValues, id) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    id: id,
    userEmail: formValues.userEmail,
    userUniqueId: formValues.userUniqueId,
    userPassword: formValues.userPassword,
    userNumber: formValues.userNumber,
    userName: formValues.userName,
    userNameHurigana: formValues.userNameHurigana,
    sex: formValues.sex || "-",
    postCode: formValues.postCode || "-",
    address01: formValues.address01 || "-",
    address02: formValues.address02 || "-",
    userHousePhone: formValues.userHousePhone || "-",
    userPhone: formValues.userPhone || "-",
    userFaxNumber: formValues.userFaxNumber || "-",
    userMemo: formValues.userMemo || "-",
    emergencyContactRelation: formValues.emergencyContactRelation || "-",
    emergencyContactName: formValues.emergencyContactName || "-",
    emergencyContactAddress: formValues.emergencyContactAddress || "-",
    emergencyContactPhone: formValues.emergencyContactPhone || "-",
    belongTo: formValues.belongTo || "-",
    insuranceType: formValues.insuranceType || "-",
    workerType: formValues.workerType || "-",
    workScope: formValues.workScope || "-",
    enrollmentType: formValues.enrollmentType || "-",
    userGroup: formValues.userGroup.toString() || "-",
    authority: formValues.authority || "従業員",
    userBirth: formValues.userBirth || "",
    employmentStartAt: formValues.employmentStartAt || "-",
    employmentEndAt: formValues.employmentEndAt || "-",
    employmentType: "-",
    userLastNumber:
      formValues.userLastNumber === null ? "-" : formValues.userLastNumber,
  };
  return axios
    .post(
      `${HOST}/user/update`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        alert("ユーザー情報が更新されました。");
        return res.data.data;
      } else {
        console.log("업데이트 갱신", res);
        alert(res.data.message);

        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));

      return false;
    });
}

export async function createUserService(formValues) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    userEmail: formValues.userEmail,
    userUniqueId: formValues.userUniqueId,
    userPassword: formValues.userPassword,
    userNumber: formValues.userNumber,
    userName: formValues.userName,
    userNameHurigana: formValues.userNameHurigana,
    sex: formValues.sex || "-",
    postCode: formValues.postCode || "-",
    address01: formValues.address01 || "-",
    address02: formValues.address02 || "-",
    userHousePhone: formValues.userHousePhone || "-",
    userPhone: formValues.userPhone || "-",
    userFaxNumber: formValues.userFaxNumber || "-",
    userMemo: formValues.userMemo || "-",
    emergencyContactRelation: formValues.emergencyContactRelation || "-",
    emergencyContactName: formValues.emergencyContactName || "-",
    emergencyContactAddress: formValues.emergencyContactAddress || "-",
    emergencyContactPhone: formValues.emergencyContactPhone || "-",
    belongTo: formValues.belongTo || "-",
    insuranceType: formValues.insuranceType || "-",
    workerType: formValues.workerType || "-",
    workScope: formValues.workScope || "-",
    enrollmentType: formValues.enrollmentType || "-",
    userGroup: formValues.userGroup.toString() || "-",
    authority: formValues.authority || "従業員",
    userBirth: formValues.userBirth || "-",
    employmentStartAt: formValues.employmentStartAt || "-",
    employmentEndAt: formValues.employmentEndAt || "-",
    employmentType: formValues.employmentType || "-",
    userLastNumber: formValues.userLastNumber || "-",
  };

  return axios
    .post(
      `${HOST}/user/create`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        alert("ユーザー情報が登録されました。");
        return res.data.data;
      } else {
        alert(res.data.message);

        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));

      return false;
    });
}

export async function DeleteUserService(id) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    id: id,
  };
  return axios
    .post(
      `${HOST}/user/delete`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        alert("ユーザー情報が削除されました。");
        return res.data.data;
      } else {
        alert(res.data.message);

        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));

      return false;
    });
}

export async function createCategoryService(trimmedInput, tagGenerator) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    categoryBigClassName: tagGenerator,
    categorySmallClassName: trimmedInput,
  };
  return axios
    .post(
      `${HOST}/category/create`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function deleteCategoryService(id, tagGenerator) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    id: id,
  };
  return axios
    .post(
      `${HOST}/category/delete`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function updateCategoryService(id, value, tagGenerator) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    id: id,
    changeCategorySmallClassName: value,
  };
  return axios
    .post(
      `${HOST}/category/update`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));

      return false;
    });
}

export async function getCategoryService() {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
  };
  return axios
    .post(
      `${HOST}/category/list`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        // alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      // alert(JSON.stringify(err));
      return false;
    });
}

export async function sendNotificationPerson(
  files,
  selectedSubType01,
  selectedSubType02,
  title,
  content,
  id
) {
  const userToken = await getLocalStorage("userToken");
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    // formData.append("files", files[i], files[i].name);
    formData.append("files", files[i], encodeURIComponent(files[i].name));
  }

  formData.append("token", userToken);
  formData.append("subType01", selectedSubType01);
  formData.append("subType02", selectedSubType02);
  formData.append("title", title);
  formData.append("content", content);
  formData.append("userId", id);

  // 'Content-Type': 'multipart/form-data',
  return axios
    .post(
      `${HOST}/notification/inform/personal`, //Controll this!
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data;",
          charset: "utf-8",
        },
      }
    )
    .then((res) => {
      if (res.data.status === 200) {
        alert("送信完了しました。");
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function sendNotificationInform(
  files,
  selectedBelongTo,
  selectedSubType01,
  selectedSubType02,
  selectedInsuranceType,
  selectedemploymentType,
  selectedenrollmentType,
  selectedworkScope,
  selectedworkerType,
  selecteduserGroup,
  title,
  content
) {
  const userToken = await getLocalStorage("userToken");
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i], encodeURIComponent(files[i].name));
  }

  formData.append("token", userToken);
  formData.append("subType01", selectedSubType01);
  formData.append("subType02", selectedSubType02);
  formData.append("belongTo", selectedBelongTo);
  formData.append("insuranceType", selectedInsuranceType);
  formData.append("employmentType", selectedemploymentType);
  formData.append("enrollmentType", selectedenrollmentType);
  formData.append("workScope", selectedworkScope);
  formData.append("workerType", selectedworkerType);
  formData.append("userGroup", selecteduserGroup);
  formData.append("title", title);
  formData.append("content", content);

  // 'Content-Type': 'multipart/form-data',
  return axios
    .post(
      `${HOST}/notification/inform`, //Controll this!
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data;charset: UTF-8;",
        },
      }
    )
    .then((res) => {
      if (res.data.status === 200) {
        alert("送信完了: " + res.data.data.count + "名に送信されました。");
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}
export async function getUserDetailServiceNumber(d, userNumber) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    id: d,
    type: "admin",
    userNumber,
  };
  return axios
    .post(
      `${HOST}/user/detail/number`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert("ユーザー情報が見つかりません。");
        return false;
      }
    })
    .catch((err) => {
      alert("ユーザー情報が見つかりません。");
      return false;
    });
}
export async function getNotification(id) {
  const userToken = await getLocalStorage("userToken");
  const admin = await getLocalStorage("admininfo");

  const postData = {
    token: userToken,
    id: id,
  };

  return axios
    .post(`${HOST}/notification/list`, postData)
    .then((res) => {
      if (res.data.status === 200) {
        // admin이 "管理者B"일 때 필터링 조건 적용
        if (admin === "管理者B" || admin === "管理者C") {
          return res.data.data.filter(
            (item) =>
              item.subType01 !== "給与明細" && item.subType01 !== "勤務明細"
          );
        }

        return res.data.data;
      } else {
        // alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function getSentNotification(id) {
  const userToken = await getLocalStorage("userToken");
  const postData = {
    token: userToken,
    id: id,
  };

  return axios
    .post(`${HOST}/notification/list/group`, postData)
    .then((res) => {
      if (res.data.status === 200) {
        console.log("반응", res);
        return res.data.data;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function getSentNotificationList(id) {
  const userToken = await getLocalStorage("userToken");
  const admin = await getLocalStorage("admininfo");
  const postData = {
    notificationGroupId: id,
    token: userToken,
  };

  return axios
    .post(`${HOST}/notification/list/group/user`, postData)
    .then((res) => {
      if (res.data.status === 200) {
        // admin이 "管理者B"일 때 필터링 조건 적용
        if (admin === "管理者B" || admin === "管理者C") {
          return res.data.data.filter(
            (item) =>
              item.subType01 !== "給与明細" && item.subType01 !== "勤務明細"
          );
        }
        console.log("res.data.data", res.data.data);
        return res.data.data;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function sendNotificationStub(files, subType01, title, content) {
  const userToken = await getLocalStorage("userToken");
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    if (
      files[i].name.indexOf("給与明細") < 0 ||
      files[i].name.indexOf("勤務明細") > -1
    ) {
      alert(
        "エクセルファイルが正しくないようです。エクセルのファイル名とファイルの内容を再度ご確認ください。"
      );
      return false;
    }
    // formData.append("files", files[i], files[i].name);
    formData.append("files", files[i], encodeURIComponent(files[i].name));
  }

  formData.append("token", userToken);
  formData.append("subType01", subType01);
  formData.append("title", title);
  formData.append("content", content);

  // 'Content-Type': 'multipart/form-data',
  return axios
    .post(
      `${HOST}/notification/paystub/regist`, //Controll this!
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data;",
          charset: "utf-8",
        },
      }
    )
    .then((res) => {
      if (res.data.status === 200) {
        alert("送信完了: " + res.data.data.count + "名に送信されました。");
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        console.log("res.data.message", res.data.message);
        alert(res.data.message);

        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function sendNotificationScheduler(
  files,
  subType01,
  title,
  content
) {
  const userToken = await getLocalStorage("userToken");
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    console.log(
      "files[i].name.indexOf(subType01",
      files[i].name.indexOf(subType01),
      "ddd",
      subType01
    );
    if (files[i].name.indexOf(subType01) < 0) {
      alert(
        "エクセルファイルが正しくないようです。エクセルのファイル名とファイルの内容を再度ご確認ください。"
      );

      return false;
    }
    // formData.append("files", files[i], files[i].name);
    formData.append("files", files[i], encodeURIComponent(files[i].name));
  }

  formData.append("token", userToken);
  formData.append("subType01", subType01);
  formData.append("title", title);
  formData.append("content", content);

  // 'Content-Type': 'multipart/form-data',
  return axios
    .post(
      `${HOST}/notification/scheduler/regist`, //Controll this!
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data;",
          charset: "utf-8",
        },
      }
    )
    .then((res) => {
      console.log("res", res);
      if (res.data.status === 200) {
        alert("送信完了: " + res.data.data.count + "名に送信されました。");

        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        console.log("res.data.message", res.data.message);
        alert(res.data.message);

        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));

      return false;
    });
}

export async function sendform(
  files,
  selectedSubType01,
  selectedSubType02,
  title,
  content
) {
  const userToken = await getLocalStorage("userToken");
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    // formData.append("files", files[i], files[i].name);
    formData.append("files", files[i], encodeURIComponent(files[i].name));
  }

  formData.append("token", userToken);
  formData.append("category", selectedSubType01);
  formData.append("type", selectedSubType02);
  formData.append("title", title);
  formData.append("content", content);

  // 'Content-Type': 'multipart/form-data',
  return axios
    .post(
      `${HOST}/form/regist`, //Controll this!
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data;",
          charset: "utf-8",
        },
      }
    )
    .then((res) => {
      if (res.data.status === 200) {
        alert("様式が登録されました。");
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}
export async function getformService() {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
  };
  return axios
    .post(
      `${HOST}/form/list`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function getformDetailService(id) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    formId: id,
  };
  return axios
    .post(
      `${HOST}/form/detail`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function formDeleteService(id) {
  //Controll this!

  const userToken = await getLocalStorage("userToken");

  const postData = {
    token: userToken,
    formId: id,
  };
  return axios
    .post(
      `${HOST}/form/delete`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function formUpdateService(
  id,
  formData, // Here we accept formData instead of files
  selectedSubType01,
  selectedSubType02,
  title,
  content
) {
  const userToken = await getLocalStorage("userToken");
  formData.append("formId", id);
  formData.append("token", userToken);
  formData.append("category", selectedSubType01);
  formData.append("type", selectedSubType02);
  formData.append("title", title);
  if (
    typeof content === "object" &&
    content !== null &&
    !(content instanceof Blob) &&
    !(content instanceof File)
  ) {
    alert("エラーが発生しました。");
    return false;
  }
  formData.append("content", content);
  for (let [key, value] of formData.entries()) {
  }

  // 'Content-Type': 'multipart/form-data',
  return axios
    .post(
      `${HOST}/form/update`, //Control this!
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data;",
          charset: "utf-8",
        },
      }
    )
    .then((res) => {
      if (res.data.status === 200) {
        alert("様式が更新されました。");
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}
export async function fileDeleteService(id) {
  const userToken = await getLocalStorage("userToken");
  const postData = {
    token: userToken,
    fileId: id,
  };

  // 'Content-Type': 'multipart/form-data',
  return axios
    .post(
      `${HOST}/form/file-inactive`, //Control this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else if (res.data.status === 403) {
        removeLocalStorage("userToken");
        window.location.href = "/";
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}

export async function checkTokenInterval(token) {
  const postData = {
    token,
  };
  return axios
    .post(
      `${HOST}/user/verify`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return true;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}
export async function getNoticeUserList(
  selectedBelongTo,
  selectedInsuranceType,
  selectedemploymentType,
  selectedenrollmentType,
  selectedworkScope,
  selectedworkerType,
  selecteduserGroup
) {
  const token = await getLocalStorage("userToken");
  const postData = {
    token,
    belongTo: selectedBelongTo || "",
    insuranceType: selectedInsuranceType || "",
    employmentType: selectedemploymentType || "",
    enrollmentType: selectedenrollmentType || "",
    workScope: selectedworkScope || "",
    workerType: selectedworkerType || "",
    userGroup: selecteduserGroup || "",
  };

  // const formData = new FormData();
  // formData.append("token", token);
  // formData.append("belongTo", selectedBelongTo);
  // formData.append("insuranceType", selectedInsuranceType);
  // formData.append("employmentType", selectedemploymentType);
  // formData.append("enrollmentType", selectedenrollmentType);
  // formData.append("workScope", selectedworkScope);
  // formData.append("workerType", selectedworkerType);
  // formData.append("userGroup", selecteduserGroup);

  return axios
    .post(
      `${HOST}/user/list/notice`, //Controll this!
      postData
    )
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        alert(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      alert(JSON.stringify(err));
      return false;
    });
}
