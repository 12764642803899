import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { isValid } from "date-fns";
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";
import parse from "date-fns/parse";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  createUserService,
  getCategoryService,
} from "../../../services/ShinryuService";
import { cateList, getLocalStorage } from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
const AddUser = () => {
  class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
      return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date) {
      return format(date, "MMMd日", { locale: this.locale });
    }
  }
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  // const id = searchParams.get("id");
  const [id, setId] = useState(searchParams.get("id"));
  const [activeToggle, setActiveToggle] = useState("setting");
  const [userGroupList, setUserGroupList] = useState([
    "交通(工事)",
    "交通(施設)",
    "施設",
  ]);
  const [catlist, setCateList] = useRecoilState(cateList);
  const [selectedBigClassName, setSelectedBigClassName] = useState("");
  const [indefiniteEnd, setIndefiniteEnd] = useState(false);
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [japaneseEra, setJapaneseEra] = useState("");
  const [admin, setAdmin] = useState("");
  const [formValues, setFormValues] = useState({
    userEmail: "",
    userUniqueId: "",
    userPassword: "",
    userNumber: "",
    userName: "",
    userNameHurigana: "",
    sex: "",
    postCode: "",
    address01: "",
    address02: "",
    userHousePhone: "",
    userPhone: "",
    userFaxNumber: "",
    userMemo: "",
    emergencyContactRelation: "",
    emergencyContactName: "",
    emergencyContactAddress: "",
    emergencyContactPhone: "",
    belongTo: "",
    insuranceType: "",
    workerType: "",
    workScope: "",
    enrollmentType: "",
    userGroup: "",
    authority: "従業員",
    employmentType: "",
    userBirth: new Date(),
    employmentStartAt: new Date(),
    employmentEndAt: new Date(),
    userLastNumber: "",
    // add more inputs and groups as needed
  });
  useEffect(() => {
    init();
    getLocalStorage("admininfo")
      .then((admin) => {
        setAdmin(admin);
      })
      .catch((error) => {
        console.error("에러 발생:", error);
      });
  }, []);

  const init = async () => {
    const catList = await getCategoryService();
    const activeCatList = catList.filter((cat) => cat.status !== "INACTIVE");
    setCateList(activeCatList);
    setTags((prevState) =>
      prevState.map((tags, index) => {
        const filteredCats = activeCatList.filter(
          (cat) => cat.categoryBigClassName === String(index)
        );
        return filteredCats.map((cat) => ({
          name: cat.categorySmallClassName,
          id: cat.id,
          status: cat.status,
        }));
      })
    );
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? value : value;
    setFormValues((prevValues) => {
      if (type === "checkbox") {
        if (checked) {
          return {
            ...prevValues,
            userGroup: [...prevValues.userGroup, newValue],
          };
        } else {
          return {
            ...prevValues,
            userGroup: prevValues.userGroup.filter((v) => v !== newValue),
          };
        }
      } else {
        return {
          ...prevValues,
          [name]: newValue,
        };
      }
    });
  };
  const handleDateChange1 = (date) => {
    if (isValid(date)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        userBirth: date,
      }));
    }
    const formattedDate2 = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
      era: "short",
      year: "numeric",
    }).format(date);
    setJapaneseEra(formattedDate2);
  };

  const handleDateChange2 = (date) => {
    if (isValid(date)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        employmentStartAt: date,
      }));
    }
  };

  const handleDateChange3 = (date) => {
    if (!indefiniteEnd) {
      // Add this condition
      setFormValues((prevValues) => ({
        ...prevValues,
        employmentEndAt: date,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedValues = { ...formValues };

    // Check if userName, userUniqueId, userPassword are empty
    if (
      formValues.userName === "" ||
      formValues.userUniqueId === "" ||
      formValues.userPassword === "" ||
      formValues.userNumber === ""
    ) {
      alert("隊員番号及び姓名とID,パスワードは必須項目です。");
      return; // Stop further execution
    }

    // Format the dates to 'yyyyMMdd' before sending
    formattedValues.userBirth = format(formValues.userBirth, "yyyyMMdd");
    formattedValues.employmentStartAt = format(
      formValues.employmentStartAt,
      "yyyyMMdd"
    );
    if (indefiniteEnd) {
      formattedValues.employmentEndAt = "99991231";
    } else {
      const employmentEndDate = new Date(formValues.employmentEndAt);
      formattedValues.employmentEndAt = format(employmentEndDate, "yyyyMMdd");
    }

    await createUserService(formattedValues);
  };

  // Prevent form submission on Enter key press
  document.addEventListener("keydown", function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  });

  const parsedDate = isValid(new Date(formValues.employmentEndAt))
    ? new Date(formValues.employmentEndAt)
    : parse(formValues.employmentEndAt, "yyyyMMdd", new Date());
  return (
    <>
      <PageTitle activeMenu="ユーザー登録" motherMenu="社員管理" />
      <div className="row">
        <div className="col-lg-12">
          <div
            className="card card-body px-3"
            style={{ backgroundColor: "#dcdddd" }}>
            <div className="default-tab">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <Link
                    to="#profile-settings"
                    data-toggle="tab"
                    onClick={() => setActiveToggle("setting")}
                    className={`nav-link ${
                      activeToggle === "setting" ? "active show" : ""
                    }`}>
                    社員情報
                  </Link>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  id="profile-settings"
                  className={`tab-pane fade ${
                    activeToggle === "setting" ? "active show" : ""
                  }`}>
                  <div className="pt-4 px-3">
                    <div className="settings-form">
                      <form onSubmit={handleSubmit}>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">グループ</label>
                          <div className="basic-form">
                            <div className="form-group mb-1">
                              {tags[5].map((tag) => (
                                <div className="form-check form-check-inline">
                                  <label
                                    className="form-check-label"
                                    style={{ fontSize: "14px" }}>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="userGroup"
                                      value={tag.name}
                                      checked={
                                        formValues.userGroup &&
                                        formValues.userGroup.includes(tag.name)
                                      }
                                      onChange={handleInputChange}
                                    />
                                    {tag.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-1">
                          <label className="font-weight-bold">メモ</label>
                          <div className="basic-form">
                            <div className="form-group">
                              <textarea
                                name="userMemo"
                                value={formValues.userMemo}
                                onChange={handleInputChange}
                                className="form-control2"
                                rows="4"
                                style={{ height: "109px !important" }}
                                id="comment"></textarea>
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-row mb-3 mt-3"
                          style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            Email
                          </div>
                          <div className="form-group mb-1 col-md-4">
                            <input
                              name="userEmail"
                              value={formValues.userEmail}
                              onChange={handleInputChange}
                              type="email"
                              placeholder="Email"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            ログインID
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userUniqueId"
                              value={formValues.userUniqueId}
                              onChange={handleInputChange}
                              type="userUniqueId"
                              placeholder="ログインID"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            パスワード
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userPassword"
                              value={formValues.userPassword}
                              onChange={handleInputChange}
                              type="userPassword"
                              placeholder="パスワード"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ color: "#fff100" }}>
                            隊員番号
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <input
                              name="userNumber"
                              value={formValues.userNumber}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="隊員番号(警備プロ)"
                              className="form-control"
                            />
                          </div>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ color: "#fff100" }}>
                            Bno.
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <input
                              name="userLastNumber"
                              value={formValues.userLastNumber}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="Bno."
                              className="form-control"
                            />
                          </div>

                          <div className="form-group mb-1 col-md-1"></div>

                          <div className="mb-1 col-md-1 detail-label">性別</div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="sex"
                              value={formValues.sex}
                              onChange={handleInputChange}
                              defaultValue={"option"}
                              className="form-control">
                              <option value="">性別選択</option>
                              <option value="男">男</option>
                              <option value="女">女</option>
                            </select>
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            生年月日
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <MuiPickersUtilsProvider
                              utils={ExtendedUtils}
                              locale={jaLocale}>
                              <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="yyyy/MM/dd"
                                disableFuture
                                value={formValues.userBirth}
                                onChange={handleDateChange1}
                                okLabel="決定"
                                cancelLabel="キャンセル"
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div
                            className="mb-1 col-md-1"
                            style={{
                              padding: "3px",
                              backgroundColor: "#fff",
                              border: "1px solid #c6c6c6",
                            }}>
                            {japaneseEra}
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">氏名</div>
                          <div className="form-group mb-1 col-md-3">
                            <input
                              name="userName"
                              value={formValues.userName}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="名前"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-1 col-md-1"></div>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ backgroundColor: "#595757" }}>
                            緊急連絡先
                          </div>
                          <div className="form-group mb-1  col-md-1">
                            <input
                              name="emergencyContactRelation"
                              value={formValues.emergencyContactRelation}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="関係"
                              className="form-control"
                            />
                          </div>
                          <div className="form-group mb-1  col-md-2">
                            <input
                              name="emergencyContactName"
                              value={formValues.emergencyContactName}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="名前"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            フリガナ
                          </div>
                          <div className="form-group mb-1 col-md-3">
                            <input
                              name="userNameHurigana"
                              value={formValues.userNameHurigana}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="フリガナ"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-1 col-md-2"></div>
                          <div className="form-group mb-1  col-md-3">
                            <input
                              name="emergencyContactAddress"
                              value={formValues.emergencyContactAddress}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="住所"
                              className="form-control"
                            />
                          </div>
                          <div className="form-group mb-1  col-md-2">
                            <input
                              name="emergencyContactPhone"
                              value={formValues.emergencyContactPhone}
                              onChange={handleInputChange}
                              type="tel"
                              placeholder="電話番号"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            郵便番号
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <input
                              name="postCode"
                              value={formValues.postCode}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="郵便番号(ハイフンあり入力)"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            住所1
                          </div>
                          <div className="form-group mb-1 col-md-5">
                            <input
                              name="address01"
                              value={formValues.address01}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="住所1"
                              className="form-control"
                            />
                          </div>
                          <div className="form-group mb-1 col-md-1"></div>
                          <div className="form-group mb-1 col-md-1">
                            雇用期間
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            住所2
                          </div>
                          <div className="form-group mb-1 col-md-5">
                            <input
                              name="address02"
                              value={formValues.address02}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="住所2"
                              className="form-control"
                            />
                          </div>
                          <div className="form-group mb-1 col-md-1"></div>
                          <div className="form-group mb-1 col-md-1">
                            <MuiPickersUtilsProvider
                              utils={ExtendedUtils}
                              locale={jaLocale}>
                              <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="yyyy/MM/dd"
                                value={formValues.employmentStartAt}
                                onChange={handleDateChange2}
                                okLabel="決定"
                                cancelLabel="キャンセル"
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="form-group ml-3 mr-3 mb-1">~</div>
                          <div className="form-group mb-1 col-md-1">
                            <MuiPickersUtilsProvider
                              utils={ExtendedUtils}
                              locale={jaLocale}>
                              <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="yyyy/MM/dd"
                                maxDate="9999/12/31"
                                value={
                                  indefiniteEnd ? "9999/12/31" : parsedDate
                                }
                                onChange={handleDateChange3}
                                okLabel="決定"
                                cancelLabel="キャンセル"
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="form-group mb-1 col-md-1 ml-4">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={indefiniteEnd}
                                  onChange={(e) =>
                                    setIndefiniteEnd(e.target.checked)
                                  }
                                />
                              }
                              label="未定"
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            電話番号
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userHousePhone"
                              value={formValues.userHousePhone}
                              onChange={handleInputChange}
                              type="tel"
                              placeholder="ハイフンあり入力"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            携帯電話
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userPhone"
                              value={formValues.userPhone}
                              onChange={handleInputChange}
                              type="tel"
                              placeholder="ハイフンあり入力"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            FAX番号
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userFaxNumber"
                              value={formValues.userFaxNumber}
                              onChange={handleInputChange}
                              type="tel"
                              placeholder="ハイフンあり入力"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ backgroundColor: "#595757" }}>
                            所属
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="belongTo"
                              value={formValues.belongTo}
                              onChange={handleInputChange}
                              className="form-control">
                              <option value="">所属選択</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "4"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ backgroundColor: "#595757" }}>
                            権限
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="authority"
                              value={formValues.authority}
                              onChange={handleInputChange}
                              className="form-control">
                              <option value="">権限選択</option>
                              <option value="管理者A">管理者A</option>
                              <option value="管理者B">管理者B</option>
                              <option value="管理者C">管理者C</option>
                              <option value="従業員">従業員</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            社員区分
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="workerType"
                              value={formValues.workerType}
                              onChange={handleInputChange}
                              className="form-control">
                              <option value="">区分選択</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "7"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            保険区分
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="insuranceType"
                              value={formValues.insuranceType}
                              onChange={handleInputChange}
                              className="form-control">
                              <option value="">保険種別</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "6"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            業務区分
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="workScope"
                              value={formValues.workScope}
                              onChange={handleInputChange}
                              className="form-control">
                              <option value="">区分選択</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "8"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            在籍区分
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="enrollmentType"
                              value={formValues.enrollmentType}
                              onChange={handleInputChange}
                              className="form-control">
                              <option value="">区分選択</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "9"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <Button
                          className="btn btn-primary mr-3 rounded btn-sm"
                          type="submit"
                          style={
                            admin == "管理者C"
                              ? { display: "none" }
                              : { display: "block" }
                          }>
                          登録
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddUser;
