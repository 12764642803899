import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Editor } from "@tinymce/tinymce-react";
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";
import parse from "date-fns/parse";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Card, Table } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  DeleteUserService,
  getCategoryService,
  getNotification,
  getUserDetailServiceNumber,
  getUserUpdateService,
  sendNotificationPerson,
} from "../../../services/ShinryuService";
import {
  cateList,
  getLocalStorage,
  notiList,
  userDetail,
} from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
import profile from "./../../../images/profile/profile.png";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const UserDetail = () => {
  class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
      return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date) {
      return format(date, "MMMd日", { locale: this.locale });
    }
  }
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const id = searchParams.get("id");
  const [id, setId] = useState(searchParams.get("id"));
  const [key, setKey] = useState(searchParams.get("key"));
  const [activeToggle, setActiveToggle] = useState("setting");
  const [content, setuserDetail] = useRecoilState(userDetail);
  const [admininfo, setadminDetail] = useState("");
  const [userGroupList, setUserGroupList] = useState([
    "交通(工事)",
    "交通(施設)",
    "施設",
  ]);
  const history = useHistory();
  const query2 = useQuery();
  const [idInput, setIdInput] = useState("");
  const [query, setQuery] = useState("");
  const [notilist, setNotiList] = useRecoilState(notiList);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterLists, setFilterLists] = useState({
    cat1: [],
    cat2: [],
  });
  const [catlist, setCateList] = useRecoilState(cateList);
  const [indefiniteEnd, setIndefiniteEnd] = useState(false);
  const [selectedBigClassName, setSelectedBigClassName] = useState("");
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [formValues, setFormValues] = useState({
    userEmail: content.userEmail,
    userUniqueId: content.userUniqueId,
    userPassword: content.userPassword,
    userNumber: content.userNumber,
    userName: content.userName,
    userNameHurigana: content.userNameHurigana,
    sex: content.sex,
    postCode: content.postCode,
    address01: content.address01,
    address02: content.address02,
    userHousePhone: content.userHousePhone,
    userPhone: content.userPhone,
    userFaxNumber: content.userFaxNumber,
    userMemo: content.userMemo,
    emergencyContactRelation: content.emergencyContactRelation,
    emergencyContactName: content.emergencyContactName,
    emergencyContactAddress: content.emergencyContactAddress,
    emergencyContactPhone: content.emergencyContactPhone,
    belongTo: content.belongTo,
    insuranceType: content.insuranceType,
    workerType: content.workerType,
    workScope: content.workScope,
    enrollmentType: content.enrollmentType,
    userGroup: content.userGroup,
    authority: content.authority,
    userBirth: content.userBirth,
    employmentStartAt: content.employmentStartAt,
    employmentEndAt: content.employmentEndAt,
    employmentType: "-",
    userLastNumber:
      content.userLastNumber === null || content.userLastNumber === ""
        ? "-"
        : content.userLastNumber,
    // add more inputs and groups as needed
  });
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [files, setFiles] = useState([]);
  const [attfiles, setAttFiles] = useState([]);
  const [selectedSubType01, setSelectedSubType01] = useState("-");
  const [selectedSubType02, setSelectedSubType02] = useState("-");
  const [sortColumn, setSortColumn] = useState("notiCreatedAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [selectedcat1, setSelectedCat1] = useState("");
  const [selectedcat2, setSelectedCat2] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [admin, setAdmin] = useState("");
  const [japaneseEra, setJapaneseEra] = useState("");
  const [uniqueNotificationGroupIds, setUniqueNotificationGroupIds] = useState(
    []
  );

  const handleIdSubmit = (event) => {
    event.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("id", idInput);

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
  const handleRowClick = (d) => {
    setSelectedItem(d);
  };

  const renderOtherElements = () => {
    const matchingFiles = notilist.filter(
      (file) => file.notificationGroupId === selectedItem.notificationGroupId
    );

    return (
      <>
        {selectedItem && (
          <Card className=" border">
            <Card.Header>
              <div>
                <h2>{selectedItem.title}</h2>

                <Badge variant="dark light mr-2 rounded-lg">
                  {selectedItem.subType01}
                </Badge>
                <Badge variant="dark light rounded-lg">
                  {selectedItem.subType02}
                </Badge>
              </div>
              <i
                className="fa fa-solid fa-close font-18 mr-3 "
                onClick={() => setSelectedItem("")}></i>
            </Card.Header>
            <Card.Body>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedItem.content.replace(/\r\n/g, "<br>"),
                  }}></p>
              </div>
            </Card.Body>
            {selectedItem.fileContent &&
            matchingFiles.length > 0 &&
            (selectedItem.tags === "勤務明細" ||
              selectedItem.tags === "給与明細") ? (
              <>
                <Card.Footer>
                  <div className="form-group mb-2 mx-auto">
                    <p className="small">添付ファイル</p>

                    {matchingFiles.map((d, i) => {
                      const url = `https://docs.google.com/viewer?url=${d.fileContent}&embedded=true`;
                      return (
                        <>
                          {" "}
                          <iframe
                            src={url}
                            title={`viewer${i}`}
                            width={600}
                            height={600}></iframe>
                          <br></br>
                          <a href={d.fileContent} target="_blank">
                            <Button
                              variant="dark light btn-sm"
                              className={i > 0 ? `ml-3` : ``}>
                              {d.id ? d.fileContent.split("/").pop() : d.name}
                            </Button>
                          </a>
                        </>
                      );
                    })}
                  </div>
                </Card.Footer>
              </>
            ) : selectedItem.fileContent && matchingFiles.length > 0 ? (
              <>
                <Card.Footer>
                  <div className="form-group mb-2 mx-auto">
                    <p className="small">添付ファイル</p>

                    {/* <DocViewer
                      prefetchMethod="GET"
                      pluginRenderers={DocViewerRenderers}
                      documents={docs}
                    /> */}
                    {matchingFiles.map((d, i) => {
                      const url = `https://docs.google.com/viewer?url=${d.fileUrl}&embedded=true`;

                      // 파일 확장자 추출
                      let extension = "";
                      if (d.fileUrl) {
                        const url = `https://docs.google.com/viewer?url=${d.fileUrl}&embedded=true`;
                        extension = d.fileUrl.split(".").pop().toLowerCase();
                      }

                      // 이미지 확장자 배열
                      const imageExtensions = ["jpg", "jpeg", "png", "gif"];

                      return (
                        <div key={i} className="mb-3">
                          {/* 이미지 파일일 경우 */}

                          {imageExtensions.includes(extension) ? (
                            <img
                              src={d.fileUrl}
                              width="300"
                              style={{ margin: "2px" }}
                              alt=""
                            />
                          ) : (
                            /* 이미지 파일 이외의 파일일 경우 */
                            <iframe
                              src={url}
                              title={`viewer${i}`}
                              width={600}
                              height={600}></iframe>
                          )}
                          <br></br>
                          <a href={d.fileUrl} target="_blank" rel="noreferrer">
                            <Button variant="dark light btn-sm">
                              {d.id ? d.fileName : d.name}
                            </Button>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </Card.Footer>
              </>
            ) : null}
          </Card>
        )}
      </>
    );
  };
  useEffect(() => {
    const init = async () => {
      const searchParams = new URLSearchParams(location.search);
      const idFromURL = searchParams.get("id");
      const user = await getUserDetailServiceNumber("123", idFromURL);
      console.log("user", user);
      console.log("formValues", formValues);
      if (user && user.userBirth) {
        // 文字列 '19870618' を Date オブジェクトに変換
        const year = parseInt(user.userBirth.substring(0, 4), 10);
        const month = parseInt(user.userBirth.substring(4, 6), 10) - 1; // 月は0から始まるので、1を引く必要があります
        const day = parseInt(user.userBirth.substring(6, 8), 10);
        const dateObj = new Date(year, month, day);

        // Date オブジェクトを使用して和暦に変換
        const formattedDate = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
          era: "short",
          year: "numeric",
        }).format(dateObj);
        setJapaneseEra(formattedDate);
      }
      const sanitizedUser = {
        ...user,
        userEmail: user.userEmail || "",
        userUniqueId: user.userUniqueId || "",
        userPassword: user.userPassword || "",
        userNumber: user.userNumber || "",
        userName: user.userName || "",
        userNameHurigana: user.userNameHurigana || "",
        sex: user.sex || "",
        postCode: user.postCode || "",
        address01: user.address01 || "",
        address02: user.address02 || "",
        userHousePhone: user.userHousePhone || "",
        userPhone: user.userPhone || "",
        userFaxNumber: user.userFaxNumber || "",
        userMemo: user.userMemo || "",
        emergencyContactRelation: user.emergencyContactRelation || "",
        emergencyContactName: user.emergencyContactName || "",
        emergencyContactAddress: user.emergencyContactAddress || "",
        emergencyContactPhone: user.emergencyContactPhone || "",
        belongTo: user.belongTo || "",
        insuranceType: user.insuranceType || "",
        workerType: user.workerType || "",
        workScope: user.workScope || "",
        enrollmentType: user.enrollmentType || "",
        userGroup: user.userGroup
          ? user.userGroup.split(",").filter((value) => value.length > 1)
          : [],
        authority: user.authority || "",
        userBirth: user.userBirth || "",
        employmentStartAt: user.employmentStartAt || "",
        employmentEndAt: user.employmentEndAt || "",
        employmentType: user.employmentType || "-",
        userLastNumber:
          user.userLastNumber === null || user.userLastNumber === ""
            ? "-"
            : user.userLastNumber,
      };

      setuserDetail(sanitizedUser);
      setFormValues(sanitizedUser);

      const catList = await getCategoryService();
      const activeCatList = catList.filter((cat) => cat.status !== "INACTIVE");
      setCateList(activeCatList);
      setTags((prevState) =>
        prevState.map((tags, index) => {
          const filteredCats = activeCatList.filter(
            (cat) => cat.categoryBigClassName === String(index)
          );
          return filteredCats.map((cat) => ({
            name: cat.categorySmallClassName,
            id: cat.id,
            status: cat.status,
          }));
        })
      );
      const uniqueNotificationGroupIdList = [];

      const filteredData = await getNotification(user.id);

      const uniqueNotificationGroupIds = Array.from(
        new Set(filteredData.map((d) => d.notificationGroupId))
      );

      setNotiList(filteredData);

      setFilteredWorkers(
        filteredData.filter((d, i, arr) => {
          // Check if the current index is the first occurrence of this notificationGroupId in the array
          const isFirstOccurrence =
            arr.findIndex(
              (x) => x.notificationGroupId === d.notificationGroupId
            ) === i;
          return isFirstOccurrence;
        })
      );
    };
    if (searchParams.get("id")) {
      init();
      getLocalStorage("admininfo")
        .then((admin) => {
          setAdmin(admin);
        })
        .catch((error) => {
          console.error("에러 발생:", error);
        });
    }
    if (searchParams.get("key")) {
      init();
      getLocalStorage("admininfo")
        .then((admin) => {
          setAdmin(admin);
        })
        .catch((error) => {
          console.error("에러 발생:", error);
        });
      setActiveToggle("Message");
    }
  }, [location.search, JSON.stringify(notilist)]);

  useEffect(() => {
    if (window.location.hash === "#pay") {
      notiInit();
    }
  }, [window.location.hash]);

  const init = async () => {
    const searchParams = new URLSearchParams(location.search);
    const idFromURL = searchParams.get("id");

    const user = await getUserDetailServiceNumber("123", idFromURL);

    if (user && user.userBirth) {
      // 文字列 '19870618' を Date オブジェクトに変換
      const year = parseInt(user.userBirth.substring(0, 4), 10);
      const month = parseInt(user.userBirth.substring(4, 6), 10) - 1; // 月は0から始まるので、1を引く必要があります
      const day = parseInt(user.userBirth.substring(6, 8), 10);
      const dateObj = new Date(year, month, day);

      // Date オブジェクトを使用して和暦に変換
      const formattedDate = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
        era: "short",
        year: "numeric",
      }).format(dateObj);
      setJapaneseEra(formattedDate);
    }
    setuserDetail({
      ...user,
      userGroup: user.userGroup
        ? user.userGroup.split(",").filter((value) => value.length > 1)
        : [],
    });
    setFormValues({
      ...user,
      userGroup: user.userGroup
        ? user.userGroup.split(",").filter((value) => value.length > 1)
        : [],
    });
    const catList = await getCategoryService();
    const activeCatList = catList.filter((cat) => cat.status !== "INACTIVE");
    setCateList(activeCatList);
    setTags((prevState) =>
      prevState.map((tags, index) => {
        const filteredCats = activeCatList.filter(
          (cat) => cat.categoryBigClassName === String(index)
        );
        return filteredCats.map((cat) => ({
          name: cat.categorySmallClassName,
          id: cat.id,
          status: cat.status,
        }));
      })
    );
    const uniqueNotificationGroupIdList = [];

    const filteredData = await getNotification(user.userNumber);

    const uniqueNotificationGroupIds = Array.from(
      new Set(filteredData.map((d) => d.notificationGroupId))
    );

    setNotiList(filteredData);

    setFilteredWorkers(
      filteredData.filter((d, i, arr) => {
        // Check if the current index is the first occurrence of this notificationGroupId in the array
        const isFirstOccurrence =
          arr.findIndex(
            (x) => x.notificationGroupId === d.notificationGroupId
          ) === i;
        return isFirstOccurrence;
      })
    );
  };
  const renderTableRows = () => {
    const uniqueNotificationGroupIds = Array.from(
      new Set(currentItems.map((d) => d.notificationGroupId))
    );

    return uniqueNotificationGroupIds.map((groupId) => {
      const matchingItem = currentItems.find(
        (item) => item.notificationGroupId === groupId
      );

      return (
        <tr
          key={matchingItem.notiId}
          onClick={() => handleRowClick(matchingItem)}>
          <td className="text-left width200">{matchingItem.subType01}</td>
          <td className="text-left width200">{matchingItem.subType02}</td>
          <td className="text-left text-primary">{matchingItem.title}</td>
          <td className="text-left width140 text-center">
            {matchingItem.isRead === "UNREAD" ? "未読" : "既読"}
          </td>
          <td className="text-left width200">
            <b>{matchingItem.sentUserGrade}</b> - {matchingItem.sentUserName}
          </td>
          <td className="width200">
            {format(new Date(matchingItem.notiCreatedAt), "yyyy/MM/dd　HH:mm")}
          </td>
        </tr>
      );
    });
  };
  const renderTableRows2 = () => {
    const uniqueNotificationGroupIds = Array.from(
      new Set(currentItems.map((d) => d.notificationGroupId))
    );

    return uniqueNotificationGroupIds
      .map((groupId) => {
        const matchingItem = currentItems.find(
          (item) => item.notificationGroupId === groupId
        );

        // If subType01 is "給与明細" or "勤務明細"
        if (
          matchingItem.subType01 === "給与明細" ||
          matchingItem.subType01 === "勤務明細"
        ) {
          return (
            <tr
              key={matchingItem.notiId}
              onClick={() => handleRowClick(matchingItem)}>
              <td className="text-left text-primary">{matchingItem.title}</td>
              <td className="text-left width200">
                {matchingItem.isRead === "UNREAD" ? "未読" : "既読"}
              </td>
              <td className="text-left width200">
                {matchingItem.sentUserGrade}
              </td>
              <td className="width200">
                {format(
                  new Date(matchingItem.notiCreatedAt),
                  "yyyy/MM/dd　HH:mm"
                )}
              </td>
            </tr>
          );
        }
      })
      .filter(Boolean); // This will remove any undefined values in the mapped array
  };
  const renderTableRows3 = () => {
    const uniqueNotificationGroupIds = Array.from(
      new Set(currentItems.map((d) => d.notificationGroupId))
    );

    return uniqueNotificationGroupIds
      .map((groupId) => {
        const matchingItem = currentItems.find(
          (item) => item.notificationGroupId === groupId
        );

        // If subType01 is "給与明細" or "勤務明細"
        if (matchingItem.subType01 === "源泉徴収") {
          return (
            <tr
              key={matchingItem.notiId}
              onClick={() => handleRowClick(matchingItem)}>
              <td className="text-left text-primary">{matchingItem.title}</td>
              <td className="text-left width200">
                {matchingItem.isRead === "UNREAD" ? "未読" : "既読"}
              </td>
              <td className="text-left width200">
                {matchingItem.sentUserGrade}
              </td>
              <td className="width200">
                {format(
                  new Date(matchingItem.notiCreatedAt),
                  "yyyy/MM/dd　HH:mm"
                )}
              </td>
            </tr>
          );
        }
      })
      .filter(Boolean); // This will remove any undefined values in the mapped array
  };

  const notiInit = () => {
    // if(noti 저장된 상태 값이 있는 지 체크){
    //   skip
    // }else{
    //   api 호출
    // }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? value : value;
    setFormValues((prevValues) => {
      if (type === "checkbox") {
        if (checked) {
          return {
            ...prevValues,
            userGroup: [...prevValues.userGroup, newValue],
          };
        } else {
          return {
            ...prevValues,
            userGroup: prevValues.userGroup.filter((v) => v !== newValue),
          };
        }
      } else {
        return {
          ...prevValues,
          [name]: newValue,
        };
      }
    });
  };

  const handleDateChange1 = (date) => {
    const formattedDate = format(date, "yyyy/MM/dd", { locale: jaLocale });
    setFormValues((prevValues) => ({
      ...prevValues,
      userBirth: formattedDate.replace(/\//g, ""),
    }));
    const formattedDate2 = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
      era: "short",
      year: "numeric",
    }).format(date);
    setJapaneseEra(formattedDate2);
  };

  const handleDateChange2 = (date) => {
    const formattedDate = format(date, "yyyy/MM/dd", { locale: jaLocale });
    setFormValues((prevValues) => ({
      ...prevValues,
      employmentStartAt: formattedDate.replace(/\//g, ""),
    }));
  };

  const handleDateChange3 = (date) => {
    if (!indefiniteEnd) {
      // Add this condition
      const formattedDate = format(date, "yyyy/MM/dd", { locale: jaLocale });
      setFormValues((prevValues) => ({
        ...prevValues,
        employmentEndAt: formattedDate.replace(/\//g, ""),
      }));
    }
  };
  useEffect(() => {
    if (indefiniteEnd) {
      setFormValues((prev) => ({ ...prev, employmentEndAt: "99991231" }));
    }
  }, [indefiniteEnd]);

  const handleEditorChange = (content, editor) => {
    setDesc(content);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formValues.userName === "" || formValues.userNumber === "") {
      alert("隊員番号または姓名が空白状態です。");
      return; // Stop further execution
    }
    await getUserUpdateService(formValues, formValues.id);
    init();
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    const _files = e.target.files;
    const fileList = Array.from(_files);

    setFiles([...files, ...fileList]);
    // await sendNotificationInform(fileList)
  };
  const handleSubmitUpload = async (e) => {
    e.preventDefault();

    await sendNotificationPerson(
      files,
      selectedSubType01,
      selectedSubType02,
      title,
      desc,
      formValues.id
    );
  };
  const handleDeleteSubmit = async () => {
    const result = window.confirm("本当に削除してもよろしいですか？");

    if (result) {
      await DeleteUserService(content.id);
      await history.goBack();
    }
  };

  const handleFilterAndSearch = () => {
    const filteredWorkers = notilist.filter((d) => {
      // Check if the worker matches the selected belongTo option
      if (selectedcat1 && d.subType01 !== selectedcat1) {
        return false;
      }

      // Check if the worker matches the selected insuranceType option
      // if (selectedGroup && d.insuranceType !== selectedGroup) {
      //   return false;
      // }

      // Check if the worker matches the selected insuranceType option
      if (selectedcat2 && d.subType02 !== selectedcat2) {
        return false;
      }

      const rowString = Object.values(d).join("").toLowerCase();
      if (query && !rowString.includes(query.toLowerCase())) {
        return false;
      }

      // If all filters match, include the worker in the filtered array
      return true;
    });
    setFilteredWorkers(filteredWorkers);
    setCurrentPage(0);
  };
  const handleSort = (column) => {
    // If the same column is clicked again, reverse the sorting direction
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      setCurrentPage(0);
    } else {
      setSortColumn(column);
      setSortDirection("asc");
      setCurrentPage(0);
    }
  };
  const sortedWorkers = useMemo(() => {
    let sorted = [...filteredWorkers];

    if (sortColumn) {
      sorted = sorted.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (sortColumn === "notiCreatedAt") {
          // Sort by createdAt in descending order
          return new Date(valueB) - new Date(valueA);
        }

        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      });

      if (sortDirection === "asc") {
        sorted = sorted.reverse();
      }
    }

    return sorted;
  }, [filteredWorkers, sortColumn, sortDirection]);
  const itemsPerPage = 12000;
  const totalPages = Math.ceil(sortedWorkers.length / itemsPerPage);
  const pageCount = Math.ceil(sortedWorkers.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedWorkers.slice(startIndex, endIndex);
  const handlePagination = (newPage) => {
    if (newPage < 0) {
      return;
    }
    setCurrentPage(newPage);
  };
  const isPageNegative = startIndex < 0 || endIndex > sortedWorkers.length;
  function calculateAge(birth) {
    const today = new Date();
    const thisYear = today.getFullYear();
    const birthYear = parseInt(birth.substr(0, 4), 10);
    const birthMonth = parseInt(birth.substr(4, 2), 10) - 1; // JS의 월은 0부터 시작하므로 1을 뺍니다.
    const birthDay = parseInt(birth.substr(6, 2), 10);

    let age = thisYear - birthYear;

    // 생일을 아직 안 맞았다면 1을 빼줍니다.
    if (
      today.getMonth() < birthMonth ||
      (today.getMonth() === birthMonth && today.getDate() < birthDay)
    ) {
      age--;
    }

    return age;
  }
  return (
    <>
      <div className="custom-row">
        <PageTitle activeMenu="ユーザー情報" motherMenu="社員情報" />
        <div className="custom-header">
          <div className="input-group search-area rounded d-inline-flex mr-sm-3">
            <input
              type="text"
              value={idInput}
              className="form-control"
              placeholder="隊員番号"
              onChange={(e) => setIdInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleIdSubmit(e);
                }
              }}
            />
            <div className="input-group-append" onClick={handleIdSubmit}>
              <span className="input-group-text2">
                <i className="flaticon-381-search-2" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div
            className="card card-body px-3"
            style={{ backgroundColor: "#dcdddd" }}>
            <div className="profile-info" style={{ backgroundColor: "#fff" }}>
              <div className="profile-photo" style={{ width: "150px" }}>
                <img
                  src={profile}
                  className="img-fluid rounded-circle"
                  alt="profile"
                />
              </div>
              <div
                className="profile-details pl-3 justify-content-between"
                style={{ fontSize: "14px" }}>
                <div className="profile-name px-3 pt-0">
                  <h3
                    className=" mb-0 font-weight-bold"
                    style={{ fontSize: "20px" }}>
                    {content.userName}　　年齢：
                    {content.userBirth ? calculateAge(content.userBirth) : "-"}
                    才
                  </h3>
                  <h4
                    className="text-muted mt-2"
                    style={{ fontWeight: "bold", fontSize: "14px" }}>
                    隊員番号：{content.userNumber}　　
                    <span style={{ fontWeight: "normal" }}>
                      性別：{content.sex}
                    </span>
                  </h4>
                  <p className="mb-0">
                    グループ：
                    {Array.isArray(content.userGroup)
                      ? content.userGroup.join(" / ")
                      : content.userGroup}
                  </p>
                  <p className="mb-0">
                    雇用期間：{content.employmentStartAt}~
                    {content.employmentEndAt}
                  </p>
                  <p className="mt-2 mb-2">所属：{content.belongTo}</p>

                  <div className="row" style={{ width: "400px" }}>
                    <p className="mb-0 col-6">社員区分：{content.workerType}</p>
                    <p className="mb-0 col-6">
                      保険区分：{content.insuranceType}
                    </p>
                  </div>
                  <div className="row">
                    <p className="mb-0 col-6">業務区分：{content.workScope}</p>
                    <p className="mb-0 col-6">
                      在籍区分：{content.enrollmentType}
                    </p>
                  </div>
                  <h4
                    className="text-muted mt-2 mb-2 font-weight-bold"
                    style={{ fontSize: "14px" }}>
                    Email：{content.userEmail}
                  </h4>
                </div>

                <div className="profile-name px-3 pt-0">
                  <Link
                    to="#message"
                    data-toggle="tab"
                    onClick={() => setActiveToggle("Message")}
                    className={`nav-link ${
                      activeToggle === "Message" ? "active show" : ""
                    }`}>
                    <Button className="mr-2 rounded btn-sm" variant="primary">
                      メッセージ
                    </Button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="default-tab">
              <ul
                className="nav nav-tabs"
                style={{ borderBottom: "1px solid #727171" }}>
                <li className="nav-item">
                  <Link
                    to="#profile-settings"
                    data-toggle="tab"
                    onClick={() => setActiveToggle("setting")}
                    className={`nav-link ${
                      activeToggle === "setting" ? "active show" : ""
                    }`}>
                    社員情報
                  </Link>
                </li>
                <li className="nav-item" onClick={() => setActiveToggle("Pay")}>
                  <Link
                    to="#pay"
                    data-toggle="tab"
                    className={`nav-link ${
                      activeToggle === "Pay" ? "active show" : ""
                    }`}
                    style={
                      admin == "管理者C"
                        ? { display: "none" }
                        : { display: "block" }
                    }>
                    給与明細
                  </Link>
                </li>
                <li
                  className="nav-item"
                  onClick={() => setActiveToggle("Gensen")}>
                  <Link
                    to="#gensen"
                    data-toggle="tab"
                    className={`nav-link ${
                      activeToggle === "Gensen" ? "active show" : ""
                    }`}
                    style={
                      admin == "管理者C"
                        ? { display: "none" }
                        : { display: "block" }
                    }>
                    源泉徴収票
                  </Link>
                </li>
                <li
                  className="nav-item"
                  onClick={() => setActiveToggle("Message")}>
                  <Link
                    to="#message"
                    data-toggle="tab"
                    className={`nav-link ${
                      activeToggle === "Message" ? "active show" : ""
                    }`}>
                    メッセージ
                  </Link>
                </li>
                <li
                  className="nav-item"
                  onClick={() => setActiveToggle("Sent")}>
                  <Link
                    to="#sen"
                    data-toggle="tab"
                    className={`nav-link ${
                      activeToggle === "Sent" ? "active show" : ""
                    }`}>
                    メッセージ送信履歴
                  </Link>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  id="profile-settings"
                  className={`tab-pane fade ${
                    activeToggle === "setting" ? "active show" : ""
                  }`}>
                  <div className="pt-4 px-3">
                    <div className="settings-form">
                      <form onSubmit={handleSubmit}>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">グループ</label>
                          <div className="basic-form">
                            <div className="form-group mb-1">
                              {tags[5].map((tag) => (
                                <div className="form-check form-check-inline">
                                  <label
                                    className="form-check-label"
                                    style={{ fontSize: "14px" }}>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="userGroup"
                                      value={tag.name}
                                      checked={
                                        formValues.userGroup &&
                                        formValues.userGroup.includes(tag.name)
                                      }
                                      style={{
                                        backgroundColor: "#fff !important",
                                      }}
                                      onChange={handleInputChange}
                                    />
                                    {tag.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">メモ</label>
                          <div className="basic-form">
                            <div className="form-group">
                              <textarea
                                name="userMemo"
                                value={formValues.userMemo}
                                onChange={handleInputChange}
                                className="form-control2"
                                rows="4"
                                id="comment"
                                readOnly={
                                  admin !== "管理者A" && admin !== "管理者B"
                                }
                                style={
                                  admin !== "管理者A" && admin !== "管理者B"
                                    ? {
                                        backgroundColor: "#ddd",
                                        height: "109px !important",
                                      }
                                    : { height: "109px !important" }
                                }></textarea>
                            </div>
                          </div>
                        </div>
                        <div
                          className="form-row mb-3 mt-3"
                          style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            Email
                          </div>
                          <div className="form-group mb-1 col-md-4">
                            <input
                              name="userEmail"
                              value={formValues.userEmail}
                              onChange={handleInputChange}
                              type="email"
                              placeholder="Email"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            ログインID
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userUniqueId"
                              value={formValues.userUniqueId}
                              onChange={handleInputChange}
                              type="userUniqueId"
                              placeholder="ログインID"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            パスワード
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userPassword"
                              onChange={handleInputChange}
                              value={formValues.userPassword}
                              type="password"
                              placeholder="パスワード"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row" style={{ margin: 0 }}>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ color: "#fff100" }}>
                            隊員番号
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <input
                              name="userNumber"
                              value={formValues.userNumber}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="隊員番号(警備プロ)"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ color: "#fff100" }}>
                            Bno.
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <input
                              name="userLastNumber"
                              value={formValues.userLastNumber}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="Bno."
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>

                          <div className="form-group mb-1 col-md-1"></div>

                          <div className="mb-1 col-md-1 detail-label">性別</div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="sex"
                              value={formValues.sex}
                              onChange={handleInputChange}
                              defaultValue={"option"}
                              className="form-control"
                              disabled={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }>
                              <option value="">性別選択</option>
                              <option value="男">男</option>
                              <option value="女">女</option>
                            </select>
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            生年月日
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <MuiPickersUtilsProvider
                              utils={ExtendedUtils}
                              locale={jaLocale}>
                              <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="yyyy/MM/dd"
                                disableFuture
                                value={parse(
                                  formValues.userBirth,
                                  "yyyyMMdd",
                                  new Date()
                                )}
                                onChange={handleDateChange1}
                                okLabel="決定"
                                cancelLabel="キャンセル"
                                readOnly={
                                  admin !== "管理者A" && admin !== "管理者B"
                                }
                                style={
                                  admin !== "管理者A" && admin !== "管理者B"
                                    ? { backgroundColor: "#ddd" }
                                    : {}
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div
                            className="mb-1 col-md-1"
                            style={{
                              padding: "3px",
                              backgroundColor: "#fff",
                              border: "1px solid #c6c6c6",
                            }}>
                            {japaneseEra}
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">氏名</div>
                          <div className="form-group mb-1 col-md-3">
                            <input
                              name="userName"
                              value={formValues.userName}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="名前"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="mb-1 col-md-1"></div>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ backgroundColor: "#595757" }}>
                            緊急連絡先
                          </div>
                          <div className="form-group mb-1  col-md-1">
                            <input
                              name="emergencyContactRelation"
                              value={formValues.emergencyContactRelation}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="関係"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="form-group mb-1  col-md-2">
                            <input
                              name="emergencyContactName"
                              value={formValues.emergencyContactName}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="名前"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            フリガナ
                          </div>
                          <div className="form-group mb-1 col-md-3">
                            <input
                              name="userNameHurigana"
                              value={formValues.userNameHurigana}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="フリガナ"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="mb-1 col-md-2"></div>
                          <div className="form-group mb-1  col-md-3">
                            <input
                              name="emergencyContactAddress"
                              value={formValues.emergencyContactAddress}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="住所"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="form-group mb-1  col-md-2">
                            <input
                              name="emergencyContactPhone"
                              value={formValues.emergencyContactPhone}
                              onChange={handleInputChange}
                              type="tel"
                              placeholder="電話番号"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            郵便番号
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <input
                              name="postCode"
                              value={formValues.postCode}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="郵便番号(ハイフンあり入力)"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            住所1
                          </div>
                          <div className="form-group mb-1 col-md-5">
                            <input
                              name="address01"
                              value={formValues.address01}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="住所1"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="form-group mb-1 col-md-1"></div>
                          <div className="form-group mb-1 col-md-1">
                            雇用期間
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            住所2
                          </div>
                          <div className="form-group mb-1 col-md-5">
                            <input
                              name="address02"
                              value={formValues.address02}
                              onChange={handleInputChange}
                              type="text"
                              placeholder="住所2"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="form-group mb-1 col-md-1"></div>
                          <div className="form-group mb-1 col-md-1">
                            <MuiPickersUtilsProvider
                              utils={ExtendedUtils}
                              locale={jaLocale}>
                              <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="yyyy/MM/dd"
                                value={parse(
                                  formValues.employmentStartAt,
                                  "yyyyMMdd",
                                  new Date()
                                )}
                                onChange={handleDateChange2}
                                okLabel="決定"
                                cancelLabel="キャンセル"
                                readOnly={
                                  admin !== "管理者A" && admin !== "管理者B"
                                }
                                style={
                                  admin !== "管理者A" && admin !== "管理者B"
                                    ? { backgroundColor: "#ddd" }
                                    : {}
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="form-group ml-3 mr-3 mb-1">~</div>
                          <div className="form-group mb-1 col-md-1">
                            <MuiPickersUtilsProvider
                              utils={ExtendedUtils}
                              locale={jaLocale}>
                              <DatePicker
                                autoOk
                                label=""
                                clearable
                                maxDate="9999/12/31"
                                format="yyyy/MM/dd"
                                value={
                                  indefiniteEnd
                                    ? "9999/12/31"
                                    : parse(
                                        formValues.employmentEndAt,
                                        "yyyyMMdd",
                                        new Date()
                                      )
                                }
                                onChange={handleDateChange3}
                                okLabel="決定"
                                cancelLabel="キャンセル"
                                readOnly={
                                  (admin !== "管理者A" &&
                                    admin !== "管理者B") ||
                                  indefiniteEnd
                                }
                                style={
                                  admin !== "管理者A" && admin !== "管理者B"
                                    ? { backgroundColor: "#ddd" }
                                    : {}
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="form-group mb-1 col-md-1 ml-4">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={indefiniteEnd}
                                  onChange={(e) =>
                                    setIndefiniteEnd(e.target.checked)
                                  }
                                  disabled={
                                    admin !== "管理者A" && admin !== "管理者B"
                                  }
                                />
                              }
                              label="未定"
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            電話番号
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userHousePhone"
                              value={formValues.userHousePhone}
                              onChange={handleInputChange}
                              type="tel"
                              placeholder="ハイフンあり入力"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            携帯電話
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userPhone"
                              value={formValues.userPhone}
                              onChange={handleInputChange}
                              type="tel"
                              placeholder="ハイフンあり入力"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            FAX番号
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <input
                              name="userFaxNumber"
                              value={formValues.userFaxNumber}
                              onChange={handleInputChange}
                              type="tel"
                              placeholder="ハイフンあり入力"
                              className="form-control"
                              readOnly={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ backgroundColor: "#595757" }}>
                            所属
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="belongTo"
                              value={formValues.belongTo}
                              onChange={handleInputChange}
                              className="form-control"
                              disabled={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }>
                              <option value="">所属選択</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "4"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div
                            className="mb-1 col-md-1 detail-label"
                            style={{ backgroundColor: "#595757" }}>
                            権限
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="authority"
                              value={formValues.authority}
                              onChange={handleInputChange}
                              className="form-control"
                              disabled={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }>
                              <option value="">権限選択</option>
                              <option value="管理者A">管理者A</option>
                              <option value="管理者B">管理者B</option>
                              <option value="管理者C">管理者C</option>
                              <option value="従業員">従業員</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            社員区分
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="workerType"
                              value={formValues.workerType}
                              onChange={handleInputChange}
                              className="form-control"
                              disabled={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }>
                              <option value="">区分選択</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "7"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            保険区分
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="insuranceType"
                              value={formValues.insuranceType}
                              onChange={handleInputChange}
                              className="form-control"
                              disabled={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }>
                              <option value="">保険種別</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "6"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="form-row" style={{ margin: 0 }}>
                          <div className="mb-1 col-md-1 detail-label">
                            業務区分
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="workScope"
                              value={formValues.workScope}
                              onChange={handleInputChange}
                              className="form-control"
                              disabled={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }>
                              <option value="">区分選択</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "8"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mb-1 col-md-1 detail-label">
                            在籍区分
                          </div>
                          <div className="form-group mb-1 col-md-1">
                            <select
                              name="enrollmentType"
                              value={formValues.enrollmentType}
                              onChange={handleInputChange}
                              className="form-control"
                              disabled={
                                admin !== "管理者A" && admin !== "管理者B"
                              }
                              style={
                                admin !== "管理者A" && admin !== "管理者B"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }>
                              <option value="">区分選択</option>
                              {catlist
                                .filter(
                                  (cat) => cat.categoryBigClassName === "9"
                                ) // filter by categoryBigClassName
                                .map((cat) => (
                                  <option
                                    key={cat.id}
                                    value={cat.categorySmallClassName}>
                                    {cat.categorySmallClassName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        {/* <div className="form-group mb-1">
                          <label className="font-weight-bold">写真</label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">所属</label>
                          <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group mb-1">
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    所属A
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    所属B
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    所属C
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">制服サイズ</label>
                          <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group">
                                <select
                                  defaultValue={"option"}
                                  className="form-control">
                                  <option>xxs</option>
                                  <option>xs</option>
                                  <option>s</option>
                                  <option>m</option>
                                  <option>l</option>
                                  <option>xl</option>
                                  <option>xxl</option>
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">通勤手段</label>
                          <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group mb-1">
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    社用車
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    自家用車
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    バイク
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    原付
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    自転車
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    公共交通機関
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    徒歩
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">運転免許証</label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    表面
                                  </label>
                                </div>
                              </div>
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    裏面
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">
                            自賠責保険証券
                          </label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">
                            任意保険証券
                          </label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">
                            自転車保険証券
                          </label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                       <div className="form-row" style={{margin:0}}>
                          <div className="form-group mb-1  col-md-6">
                            <label className="font-weight-bold">
                              運転免許証
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                placeholder="運転免許証番号"
                                className="form-control"
                              />
                            </div>
                            <div className="input-group mb-3">
                              <form onSubmit={(e) => e.preventDefault()}>
                                <select
                                  defaultValue={"option"}
                                  className="form-control">
                                  <option>普通免許</option>
                                  <option>準中型免許</option>
                                  <option>中型免許</option>
                                  <option>大型免許</option>
                                  <option>原付免許</option>
                                  <option>小型特殊免許</option>
                                  <option>大型特殊免許</option>
                                  <option>普通二輪免許</option>
                                  <option>大型二輪免許</option>
                                  <option>けん引免許</option>
                                  <option>普通第二種免許</option>
                                  <option>中型第二種免許</option>
                                  <option>大型第二種免許</option>
                                  <option>大型特殊第二種免許</option>
                                  <option>けん引第二種免許</option>
                                  <option>仮運転免許</option>
                                </select>
                              </form>
                            </div>
                            <div className="input-group mb-3">
                              <input
                                type="date"
                                placeholder=""
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                       <div className="form-row" style={{margin:0}}>
                          <div className="form-group mb-1 col-md-6">
                            <label className="font-weight-bold">最寄り駅</label>
                            <input
                              type="text"
                              placeholder="最寄り駅"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label className="font-weight-bold">資格</label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div> */}

                        {/*<div className="form-row" style={{margin:0}}>
                          <div className="form-group mb-1 col-md-6">
                            <label className="font-weight-bold">City</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group mb-1 col-md-4">
                            <label className="font-weight-bold">State</label>
                            <select
                              className="form-control"
                              id="inputState"
                              defaultValue="option-1">
                              <option value="option-1">Choose...</option>
                              <option value="option-2">Option 1</option>
                              <option value="option-3">Option 2</option>
                              <option value="option-4">Option 3</option>
                            </select>
                          </div>
                          <div className="form-group mb-1 col-md-2">
                            <label className="font-weight-bold">Zip</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="gridCheck"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="gridCheck">
                              Check me out
                            </label>
                          </div>
                        </div> */}
                        <Button
                          className="btn btn-primary mr-3 rounded btn-sm"
                          type="submit"
                          style={
                            admin == "管理者C"
                              ? { display: "none" }
                              : { display: "inline-block" }
                          }>
                          更新
                        </Button>
                        <Button
                          className="btn mr-3 rounded btn-sm"
                          variant="danger"
                          onClick={handleDeleteSubmit}
                          style={
                            admin == "管理者C"
                              ? { display: "none" }
                              : { display: "inline-block" }
                          }>
                          ユーザー削除
                        </Button>
                      </form>
                      {/* <Button
                          className="btn btn-primary mr-3 rounded btn-sm"
                          onClick={handleButtonClick}>
                          체크박스 테스트
                        </Button> */}
                    </div>
                  </div>
                </div>
                <div
                  id="pay"
                  className={`tab-pane fade ${
                    activeToggle === "Pay" ? "active show" : ""
                  }`}>
                  <div>
                    {/* <div className="px-3 py-4 d-flex align-items-center justify-content-start">
                    <div className="flex-row d-flex align-items-center justify-content-start">
                      <i className="fa fa-solid fa-chevron-left font-18 align-middle mx-auto"></i>

                      <h2 className="font-weight-bold mx-4">2022年</h2>
                      <i className="fa fa-solid fa-chevron-right font-18 align-middle mx-auto"></i>
                    </div>
                  </div> */}
                    <div className="tableFixHead">
                      <Table responsive hover>
                        <thead className="thead-info">
                          <tr>
                            <th
                              className="text-left"
                              onClick={() => handleSort("title")}>
                              件名
                            </th>
                            <th className="text-left">確認状況</th>
                            <th className="text-left">送信者</th>
                            <th onClick={() => handleSort("notiCreatedAt")}>
                              送信日時
                            </th>
                          </tr>
                        </thead>
                        <tbody>{renderTableRows2()}</tbody>
                      </Table>

                      {/* <Table responsive>
                      <tbody>
                        <tr>
                          <td className="width200">2023年 12月</td>
                          <td className="text-primary">
                            202201kyuyomeisai.pdf
                          </td>
                        </tr>
                        <tr>
                          <td>2023年 11月</td>
                          <td className="text-primary">
                            202201kyuyomeisai.pdf
                          </td>
                        </tr>
                        <tr>
                          <td>2023年 10月</td>
                          <td className="text-primary">
                            202201kyuyomeisai.pdf
                          </td>
                        </tr>
                      </tbody>
                    </Table> */}
                    </div>
                    {renderOtherElements()}
                  </div>
                </div>
                <div
                  id="gensen"
                  className={`tab-pane fade ${
                    activeToggle === "Gensen" ? "active show" : ""
                  }`}>
                  {/* <div className="px-3 py-4 d-flex align-items-center justify-content-start">
                    <div className="flex-row d-flex align-items-center justify-content-start">
                      <i className="fa fa-solid fa-chevron-left font-18 align-middle mx-auto"></i>
                      <h2 className="font-weight-bold mx-4">2022年</h2>
                      <i className="fa fa-solid fa-chevron-right font-18 align-middle mx-auto"></i>
                    </div>
                  </div> */}
                  <div className="tableFixHead">
                    <Table responsive hover>
                      <thead className="thead-info">
                        <tr>
                          <th
                            className="text-left"
                            onClick={() => handleSort("title")}>
                            件名
                          </th>
                          <th className="text-left">確認状況</th>
                          <th className="text-left">送信者</th>
                          <th onClick={() => handleSort("notiCreatedAt")}>
                            送信日時
                          </th>
                        </tr>
                      </thead>
                      <tbody>{renderTableRows3()}</tbody>
                    </Table>
                    {renderOtherElements()}
                    {/* <Table responsive>
                      <tbody>
                        <tr>
                          <td className="width200">2022年</td>
                          <td className="text-primary">2022gensen.pdf</td>
                        </tr>
                        <tr>
                          <td>2021年</td>
                          <td className="text-primary">2021gensen.pdf</td>
                        </tr>
                        <tr>
                          <td>2020年</td>
                          <td className="text-primary">2020gensen.pdf</td>
                        </tr>
                      </tbody>
                    </Table> */}
                  </div>
                </div>
                {/* --- */}
                <div
                  id="message"
                  className={`tab-pane fade ${
                    activeToggle === "Message" ? "active show" : ""
                  }`}>
                  <div className="pt-4 ">
                    <div className="settings-form">
                      <form onSubmit={handleSubmitUpload}>
                        <div className="form-group mb-3">
                          <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-head d-flex mb-3 align-items-center">
                                <select
                                  value={selectedSubType01}
                                  onChange={(e) =>
                                    setSelectedSubType01(e.target.value)
                                  }
                                  className={`form-control col-02 mr-3 ${
                                    selectedSubType01 === "-"
                                      ? "default-option"
                                      : ""
                                  }`}>
                                  <option value="-">カテゴリー１</option>
                                  {catlist
                                    .filter(
                                      (cat) => cat.categoryBigClassName === "0"
                                    ) // filter by categoryBigClassName
                                    .map((cat) => (
                                      <option
                                        key={cat.id}
                                        value={cat.categorySmallClassName}>
                                        {cat.categorySmallClassName}
                                      </option>
                                    ))}
                                </select>
                                <select
                                  value={selectedSubType02}
                                  onChange={(e) =>
                                    setSelectedSubType02(e.target.value)
                                  }
                                  className={`form-control col-02 mr-3 ${
                                    selectedSubType02 === "-"
                                      ? "default-option"
                                      : ""
                                  }`}>
                                  <option value="-">カテゴリー２</option>
                                  {catlist
                                    .filter(
                                      (cat) => cat.categoryBigClassName === "1"
                                    ) // filter by categoryBigClassName
                                    .map((cat) => (
                                      <option
                                        key={cat.id}
                                        value={cat.categorySmallClassName}>
                                        {cat.categorySmallClassName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            placeholder="件名"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <div className="basic-form">
                            {/* <form onSubmit={(e) => e.preventDefault()}> */}
                            <div className="form-group">
                              <Editor
                                apiKey="o1dtwll4elgd2oiuxnvmxcawshwg7h40mlme0dprvzz387hp"
                                initialValue="<p>本文入力</p>"
                                init={{
                                  height: 500,
                                  menubar: false,
                                  plugins: [
                                    "advlist autolink lists link image code charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste code help wordcount",
                                  ],
                                  toolbar:
                                    "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
                                    "bullist numlist outdent indent | removeformat | help ",
                                  content_style: "body { color: #7e7e7e }",
                                }}
                                onEditorChange={handleEditorChange}
                              />
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <div className="basic-form custom_file_input">
                            {/* <form action="#"> */}
                            <div className="input-group mb-3">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  id="file"
                                  name="file"
                                  multiple={true}
                                  className="custom-file-input"
                                  onChange={handleUpload}
                                />
                                <label className="custom-file-label">
                                  file選択
                                </label>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                        <div className="form-group mb-1 mx-auto">
                          {files.map((d, i) => {
                            return (
                              <Button
                                variant="dark light btn-sm"
                                className={i > 0 ? `ml-3` : ``}>
                                <i
                                  className="fa fa-solid fa-close font-18 mr-3 "
                                  onClick={() => {
                                    setFiles(
                                      files.filter((file, index) => index !== i)
                                    );
                                  }}></i>
                                {d.name}
                              </Button>
                            );
                          })}
                        </div>
                        <Button
                          className="btn btn-primary mr-3 rounded btn-sm"
                          type="submit">
                          送信
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>

                {/* --- */}
                <div
                  id="sent"
                  className={`tab-pane fade ${
                    activeToggle === "Sent" ? "active show" : ""
                  }`}>
                  {/* <div className="px-3 py-4 d-flex align-items-center justify-content-start">
                    <div className="flex-row d-flex align-items-center justify-content-start">
                      <i className="fa fa-solid fa-chevron-left font-18 align-middle mx-auto"></i>
                      <h2 className="font-weight-bold mx-4">2022年</h2>
                      <i className="fa fa-solid fa-chevron-right font-18 align-middle mx-auto"></i>
                    </div>
                  </div> */}
                  <div>
                    <div className="form-head d-flex mb-3 align-items-center mt-4">
                      <select
                        value={selectedcat1}
                        onChange={(e) => setSelectedCat1(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedcat1 === "" ? "default-option" : ""
                        }`}>
                        <option value="">カテゴリー1</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "0") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>

                      <select
                        value={selectedcat2}
                        onChange={(e) => setSelectedCat2(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedcat2 === "" ? "default-option" : ""
                        }`}>
                        <option value="">カテゴリー2</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "1") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>

                      <div className="input-group search-area rounded d-inline-flex ml-auto ">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="検索"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleFilterAndSearch();
                            }
                          }}
                        />
                        <div
                          className="input-group-append"
                          onClick={handleFilterAndSearch}>
                          <span className="input-group-text">
                            <i className="flaticon-381-search-2" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="tableFixHead">
                      <Table responsive hover>
                        <thead className="thead-info">
                          <tr>
                            <th
                              className="text-left width200"
                              onClick={() => handleSort("category")}>
                              カテゴリー1
                            </th>
                            <th
                              className="text-left width200"
                              onClick={() => handleSort("type")}>
                              カテゴリー2
                            </th>
                            <th
                              className="text-left"
                              onClick={() => handleSort("title")}>
                              件名
                            </th>
                            <th className="text-left width140 text-center">
                              確認状況
                            </th>
                            <th className="text-left width140 text-center">
                              送信者
                            </th>
                            <th onClick={() => handleSort("notiCreatedAt")}>
                              送信日時
                            </th>
                          </tr>
                        </thead>
                        <tbody>{renderTableRows()}</tbody>
                      </Table>
                    </div>
                    {renderOtherElements()}

                    <div className="dataTables_wrapper">
                      <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                          {startIndex + 1} ～{" "}
                          {Math.min(endIndex, sortedWorkers.length)} 表示 （総
                          {sortedWorkers.length} 件）
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate">
                            <Link
                              className={`paginate_button previous ${
                                currentPage === 0 ? "disabled" : ""
                              }`}
                              onClick={() => {
                                if (currentPage === 0) {
                                  return;
                                }
                                setCurrentPage(currentPage - 1);
                              }}>
                              戻る
                            </Link>

                            {/* {Array.from({ length: totalPages }).map((_, i) => (
                        <Link
                          key={i}
                          className={`paginate_button ${
                            currentPage === i ? "current" : ""
                          }`}
                          onClick={() => setCurrentPage(i)}>
                          {i + 1}
                        </Link>
                      ))} */}

                            <Link
                              className={`paginate_button next ${
                                currentPage === totalPages - 1 ? "disabled" : ""
                              }`}
                              onClick={() => {
                                if (currentPage === totalPages - 1) {
                                  return;
                                }
                                setCurrentPage(currentPage + 1);
                              }}>
                              次へ
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDetail;
