import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright ©
          <a href="#!" target="_blank" rel="noreferrer">
            Shinryu
          </a>{" "}
          2023
        </p>
      </div>
    </div>
  );
};

export default Footer;
