import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

import logo from "../../../images/logo.png";
import logoText from "../../../images/logo-text.png";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="nav-header">
       <Link to="/" className="brand-logo">
            <img className="logo-abbr" src={logo} alt="" />

      </Link>

      {/* <Link to={"#"} className="brand-logo">
        <svg
          id="_\u30EC\u30A4\u30E4\u30FC_2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 47.81 54.79"
          width="52"
          height="auto">
          <defs>
            <radialGradient
              id="_\u540D\u79F0\u672A\u8A2D\u5B9A\u30B0\u30E9\u30C7\u30FC\u30B7\u30E7\u30F3_430"
              cx={20.09}
              cy={28.61}
              fx={29.28}
              fy={28.61}
              r={9.24}
              gradientTransform="matrix(.07132 .99745 -1.66575 .11911 66.19 5.17)"
              gradientUnits="userSpaceOnUse">
              <stop offset={0.28} stopColor="#fff" />
              <stop offset={0.35} stopColor="#fafdfe" />
              <stop offset={0.44} stopColor="#ecf7fd" />
              <stop offset={0.54} stopColor="#d4edfc" />
              <stop offset={0.64} stopColor="#b3e0fa" />
              <stop offset={0.75} stopColor="#89cef7" />
              <stop offset={0.85} stopColor="#56b9f4" />
              <stop offset={0.96} stopColor="#1aa0f0" />
              <stop offset={1} stopColor="#0397ef" />
            </radialGradient>
            <linearGradient
              id="_\u540D\u79F0\u672A\u8A2D\u5B9A\u30B0\u30E9\u30C7\u30FC\u30B7\u30E7\u30F3_13"
              x1={10.13}
              y1={15.84}
              x2={21.88}
              y2={9.06}
              gradientUnits="userSpaceOnUse">
              <stop offset={0} stopColor="#fff" />
              <stop offset={0.1} stopColor="#c1e5fb" />
              <stop offset={0.28} stopColor="#5cbbf4" />
              <stop offset={0.41} stopColor="#1ba1f0" />
              <stop offset={0.46} stopColor="#0397ef" />
              <stop offset={0.64} stopColor="#1ba1f0" />
              <stop offset={0.65} stopColor="#1da2f1" />
              <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <style>
              {
                ".cls-2{fill:#fff;stroke:#0397ef;stroke-linejoin:bevel;stroke-width:.5px}.cls-3{fill:#e60012}.cls-4{fill:#0397ef}"
              }
            </style>
          </defs>
          <g id="_\u30EC\u30A4\u30E4\u30FC_3">
            <path
              className="cls-2"
              d="M28.02 14.37c9.73-2.74 18.29-2.66 19.55.1C45.72 6.34 38.14.25 29.05.25 18.59.25 10.1 8.34 10.1 18.31c0 2.33.48 4.55 1.32 6.59-.8-3.01 6.61-7.72 16.6-10.53Z"
            />
            <path
              className="cls-4"
              d="M31.09 25.28c9.32-2.62 16.48-7.28 16.64-9.91.53-4.68-9.99-3.74-19.72-1-9.98 2.81-17.4 7.52-16.6 10.53.02.04.03.08.04.11 1 2.92 9.71 3.05 19.63.26Z"
            />
            <path
              className="cls-2"
              d="M19.74 42.6c10.27 0 18.59-8.03 18.59-17.93 0-1.24-.13-2.46-.38-3.63-7.39-2.79-17.65-1.89-25.58 2.1-3.99 2.01-7.42 4.59-10.16 7.49C4.76 37.6 11.63 42.6 19.73 42.6Z"
            />
            <ellipse
              className="cls-4"
              cx={25.12}
              cy={50.48}
              rx={11.23}
              ry={4.31}
            />
            <path
              d="M19.21 40.01c10.27 0 18.59-8.03 18.59-17.93 0-.29-.03-.56-.04-.85l-.09-.03c-1.07 8.91-8.97 16.82-18.46 15.84-6.1-.63-10.89-4.31-14.23-9.03-.4.37-1.87 1.94-2.24 2.32 3.1 5.74 9.3 9.67 16.48 9.67Z"
              style={{
                fill: "url(#_\u540D\u79F0\u672A\u8A2D\u5B9A\u30B0\u30E9\u30C7\u30FC\u30B7\u30E7\u30F3_430)",
              }}
            />
            <path
              d="M10.47 16.43s2.87 4.37 3.02 4.25c3.85-3.17 5.88-4.28 11.2-5.96L18.73 3.61c-1.32 1.09-7.21 5.81-8.25 12.82Z"
              style={{
                fill: "url(#_\u540D\u79F0\u672A\u8A2D\u5B9A\u30B0\u30E9\u30C7\u30FC\u30B7\u30E7\u30F3_13)",
              }}
            />
            <path
              className="cls-3"
              d="m0 40.93.64-.72.09.08c.07.31.19.61.37.92.18.3.38.55.61.76.24.21.47.34.68.39s.38.01.5-.12c.09-.1.13-.22.12-.34-.02-.12-.1-.29-.26-.5-.12-.15-.32-.4-.6-.76-.28-.36-.43-.71-.46-1.03-.03-.32.07-.61.3-.88.27-.31.65-.43 1.12-.36.47.07.95.31 1.42.73.48.42.87.9 1.15 1.43l-.61.69-.08-.07c-.13-.58-.44-1.07-.9-1.48-.22-.2-.44-.32-.64-.37s-.37-.02-.48.11c-.09.11-.13.23-.1.36.03.14.12.31.28.53.17.21.35.44.55.71.2.27.33.49.39.66.06.17.1.33.1.46 0 .14-.02.27-.06.4s-.12.24-.22.36c-.29.33-.68.47-1.17.4s-.99-.33-1.5-.77c-.5-.44-.9-.95-1.22-1.55ZM7.99 46.9 7 46.34l1.02-1.81-1.89-1.07-1.02 1.81-.99-.56 2.25-3.97.99.56-.85 1.51 1.89 1.07.85-1.51.99.56-2.25 3.97ZM11.24 48.12l-1.07-.38 1.53-4.3 1.06.38-1.53 4.3ZM17.91 49.54l-1.08-.17-1.78-3.66-.51 3.31-1.04-.16.7-4.51 1.36.21 1.57 3.23.45-2.91 1.04.16-.7 4.51ZM24.52 49.22l-1.4.11-1.65-1.61-.72.06.13 1.72-1.13.09-.35-4.55 1.98-.15c.72-.06 1.24.02 1.58.22.33.2.52.49.55.88.05.6-.26 1.07-.93 1.42l1.94 1.82Zm-3.94-3.55.11 1.42.51-.04c.49-.04.8-.13.95-.29.15-.16.21-.35.19-.58-.02-.21-.1-.36-.26-.46-.16-.1-.46-.13-.9-.1l-.59.05ZM28.39 43.36l-1.03 3.22.49 1.68-1.09.31-.47-1.63-2.64-2.23 1.23-.36 1.74 1.55.6-2.23 1.17-.34ZM33.8 43.83c.27.54.3 1.02.09 1.46-.21.44-.63.82-1.26 1.13-.64.32-1.19.42-1.67.32-.48-.1-.84-.42-1.11-.95l-1.27-2.56 1.02-.51 1.26 2.53c.17.34.37.55.6.62.23.07.51.03.83-.13.33-.16.53-.36.61-.59.08-.23.04-.51-.13-.85l-1.26-2.54 1.02-.51 1.27 2.56Z"
            />
          </g>
        </svg>

        <svg
          id="_\u30EC\u30A4\u30E4\u30FC_2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 228.84 34.18"
          width="auto"
          height="22">
          <defs>
            <style>{".cls-1{fill:#3e3a39}"}</style>
          </defs>
          <g id="_\u30EC\u30A4\u30E4\u30FC_1-2">
            <path
              className="cls-1"
              d="m214.12 4.58.81-4.58h-17.7l-.81 4.58h-13.44l-2.33 13.23h5.3l1.4-7.93h22.86l-2.94 16.7s-.58 2.3-3.32 2.3h-16.39l-.93 5.3h30.59c5.96 0 7.36-5.3 7.36-5.3l4.28-24.3h-14.72ZM174.91 15.02h-22.7l-.54 3.06h12.48l-2.3 13.04h-14.2l-.54 3.06h29.1l.55-3.06h-4.69l2.84-16.1zM24.9.68H5.91l-.94 5.29h18.99L24.9.68zM23.41 9.11H4.42l-.93 5.3h18.99l.93-5.3z"
            />
            <path
              className="cls-1"
              d="M26.44 26.58s-.51 2.3-3.08 2.3H.93L0 34.18h36.28c6.3 0 7.46-5.3 7.46-5.3L48.7.68H31l-4.57 25.9ZM73.79.68H54.8l-.93 5.29h18.99l.93-5.29zM79.9.68l-4.57 25.9s-.51 2.3-3.08 2.3H49.83l-.93 5.3h36.28c6.3 0 7.45-5.3 7.45-5.3L97.6.68H79.9ZM103.92.68l-3.63 20.58h5.29L109.21.68h-5.29zM124.79 26.58s-.58 2.3-3.32 2.3h-14.64l-.94 5.3h28.83c5.97 0 7.36-5.3 7.36-5.3l4.97-28.2h-17.7l-4.57 25.9Z"
            />
          </g>
        </svg>
      </Link> */}
      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
