import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import pic1 from "./../../../images/profile/small/pic1.jpg";
import Editable from "../Editable";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";

const Kinmumeisai = () => {
  const history = useHistory();
  const routeChange = () => {
    let path = `/shinryu-userdetail`;
    history.push(path);
  };

  return (
    <>
      <PageTitle activeMenu="給与明細" motherMenu="shinryu" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Table bordered className="meisai" style={{ width: "30%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontSize: "16px" }} className="p-5">
                        給与・勤務明細書<br></br>
                    {`{E열}`}<br></br>
                    {`{F열}`}<br></br>
                    {`{G열}`}<br></br>
                      <br></br>
                      {`{D열}`} 殿
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="mb-2 text-right">
              {`{C열}`}<br></br>
              隊員CD：{`{A열}`}

              </div>
              <Table bordered className="meisai">
              <tbody>
<tr>
<td style={{width: "5%"}} rowspan="2">勤
怠</td>
<td>勤務日数</td>
<td>勤務回数</td>
<td>実労働時間</td>
<td>時間外時間</td>
<td>深夜時間</td>
<td>休日出勤時間</td>
<td></td>
<td>有給取得数</td>
<td>有給残日数</td>
</tr>
<tr>
<td>{`{H열}`}</td>
<td>{`{I열}`}</td>
<td>{`{J열}`}</td>
<td>{`{K열}`}</td>
<td>{`{L열}`}</td>
<td>{`{M열}`}</td>
<td></td>
<td>{`{N열}`}</td>
<td>{`{O열}`}</td>
</tr>
<tr>
<td style={{width: "5%"}} rowspan="6">支
給</td>
<td>所定内賃金</td>
<td>みなし残業①</td>
<td>みなし残業②</td>
<td>時間外手当</td>
<td>深夜手当</td>
<td>休日出勤手当</td>
<td>休業手当</td>
<td>有給手当</td>
<td>勤怠控除</td>
</tr>
<tr>
<td>{`{P열}`}</td>
<td>{`{Q열}`}</td>
<td>{`{R열}`}</td>
<td>{`{S열}`}</td>
<td>{`{T열}`}</td>
<td>{`{U열}`}</td>
<td>{`{V열}`}</td>
<td>{`{W열}`}</td>
<td>{`{X열}`}</td>
</tr>
<tr>
<td>交通費</td>
<td>現場手当</td>
<td>業務手当</td>
<td>資格手当</td>
<td>役職手当</td>
<td>通信費</td>
<td>車両手当</td>
<td>出張手当</td>
<td>立替金</td>
</tr>
<tr>
<td>{`{Y열}`}</td>
<td>{`{Z열}`}</td>
<td>{`{AA열}`}</td>
<td>{`{AB열}`}</td>
<td>{`{AC열}`}</td>
<td>{`{AD열}`}</td>
<td>{`{AE열}`}</td>
<td>{`{AF열}`}</td>
<td>{`{AG열}`}</td>
</tr>
<tr>
<td>研修手当</td>
<td>研修交通費</td>
<td>調整手当①</td>
<td>調整手当②</td>
<td>その他手当①</td>
<td>その他手当②</td>
<td>前月差額分</td>
<td>前月交通費</td>
<td><strong>総支給額</strong></td>
</tr>
<tr>
<td>{`{AH열}`}</td>
<td>{`{AI열}`}</td>
<td>{`{AJ열}`}</td>
<td>{`{AK열}`}</td>
<td>{`{AL열}`}</td>
<td>{`{AM열}`}</td>
<td>{`{AN열}`}</td>
<td>{`{AO열}`}</td>
<td><strong>{`{AP열}`}</strong></td>
</tr>
<tr>
<td style={{width: "5%"}} rowspan="4">控
除</td>
<td>健康保険料</td>
<td>介護保険料</td>
<td>厚生年金保険料</td>
<td>雇用保険料</td>
<td>所得税</td>
<td>住民税</td>
<td></td>
<td></td>
<td></td>
</tr>
<tr>
<td>{`{AQ열}`}</td>
<td>{`{AR열}`}</td>
<td>{`{AS열}`}</td>
<td>{`{AT열}`}</td>
<td>{`{AU열}`}</td>
<td>{`{AV열}`}</td>
<td></td>
<td></td>
<td></td>
</tr>
<tr>
<td>制服預り金</td>
<td>備品</td>
<td>支払済み分</td>
<td>前払</td>
<td>前払(振)</td>
<td>その他控除</td>
<td></td>
<td></td>
<td><strong>控除計</strong></td>
</tr>
<tr>
<td>{`{AW열}`}</td>
<td>{`{AX열}`}</td>
<td>{`{AY열}`}</td>
<td>{`{AZ열}`}</td>
<td>{`{BA열}`}</td>
<td>{`{BB열}`}</td>
<td></td>
<td></td>
<td><strong>{`{BC열}`}</strong></td>
</tr>
<tr>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td><strong>差引支給額</strong></td>
</tr>
<tr>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td><strong>{`{BD열}`}</strong></td>
</tr>
</tbody>
              </Table>
              <Table bordered className="meisai">
              <tbody>
<tr>
<td>日付</td>
<td>曜</td>
<td>現場名</td>
<td>勤務名</td>
<td>勤務時間</td>
<td>勤務数</td>
<td>実労H</td>
<td>昼間Ｈ</td>
<td>深夜Ｈ</td>
<td>休日H</td>
<td>交通費</td>
</tr>
<tr>
<td>1/1({`{BF열}`})</td>
<td>日</td>
<td>ヴェルドワイエ芦屋</td>
<td>24時間</td>
<td>9:00 - 9:00</td>
<td>1.00</td>
<td>16</td>
<td>14</td>
<td>2</td>
<td></td>
<td>820</td>
</tr>
<tr>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
<td>{`{BG열}`}</td>
</tr>
<tr>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>{`{CK열}`}</td>
<td>{`{CL열}`}</td>
<td>{`{CM열}`}</td>
<td>{`{CN열}`}</td>
<td>{`{CO열}`}</td>
<td>{`{CP열}`}</td>
</tr>
</tbody>
              </Table>
            </Card.Body>
          </Card>
          {/* <!-- /# card --> */}
        </Col>
      </Row>
    </>
  );
};
export default Kinmumeisai;
