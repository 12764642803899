import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import * as XLSX from "xlsx";
import {
  getCategoryService,
  getUserCsvService,
  getUserListFilterListService,
  getUserListService,
} from "../../../services/ShinryuService";
import { cateList, getLocalStorage, userList } from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
const UserList = () => {
  const [updateCount, setUpdateCount] = useState(0);
  const [initialUsers, setInitialUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [globalFilter, setGlobalFilter] = useSessionStorageState(
    "globalFilter",
    ""
  );
  const history = useHistory();
  const [query, setQuery] = useSessionStorageState("query", "");
  const [query_employmentStartAt, setQuery_employmentStartAt] =
    useSessionStorageState("query_employmentStartAt", "");
  const [query_employmentEndAt, setQuery_employmentEndAt] =
    useSessionStorageState("query_employmentEndAt", "");

  const [currentPage, setCurrentPage] = useState(0);
  const [filterLists, setFilterLists] = useState({
    belongTo: [],
    employmentType: [],
    enrollmentType: [],
    insuranceType: [],
    workScope: [],
    workerType: [],
    userGroup: [],
  });
  const [selectedemploymentType, setSelectedemploymentType] = useState("");
  const [catlist, setCateList] = useRecoilState(cateList);
  const [selectedBigClassName, setSelectedBigClassName] = useState("");
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [selectedBelongTo, setSelectedBelongTo] = useSessionStorageState(
    "selectedBelongTo",
    ""
  );
  const [selectedGroup, setSelectedGroup] = useSessionStorageState(
    "selectedGroup",
    ""
  );
  const [selectedInsuranceType, setSelectedInsuranceType] =
    useSessionStorageState("selectedInsuranceType", "");
  const [selectedenrollmentType, setSelectedenrollmentType] =
    useSessionStorageState("selectedenrollmentType", "");
  const [selectedworkScope, setSelectedworkScope] = useSessionStorageState(
    "selectedworkScope",
    ""
  );
  const [selectedworkerType, setSelectedworkerType] = useSessionStorageState(
    "selectedworkerType",
    ""
  );
  const [workers, setuserList] = useRecoilState(userList);
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [sortColumn, setSortColumn] = useState("userNumber");
  const [sortDirection, setSortDirection] = useState("desc");
  const [csv, setCsvList] = useState(null);
  const [admin, setAdmin] = useState("");
  const onSearchChange = (e) => {
    setGlobalFilter(e.target.value);
  };
  // group the categorySmallClassName values by categoryBigClassName
  const bigClassNames = catlist.reduce((acc, curr) => {
    const { categoryBigClassName, categorySmallClassName } = curr;
    if (acc[categoryBigClassName]) {
      acc[categoryBigClassName].push(curr);
    } else {
      acc[categoryBigClassName] = [curr];
    }
    return acc;
  }, {});
  useEffect(() => {
    const initialize = async () => {
      const users = await init(); // activeUsers 반환
      setActiveUsers(users); // state 업데이트
      getLocalStorage("admininfo")
        .then((admin) => {
          setAdmin(admin);
        })
        .catch((error) => {
          console.error("에러 발생:", error);
        });
    };

    initialize(); // async 함수 실행
  }, []);
  function useSessionStorageState(key, defaultValue) {
    const [state, setState] = useState(
      () => sessionStorage.getItem(key) || defaultValue
    );

    useEffect(() => {
      sessionStorage.setItem(key, state);
    }, [key, state]);

    return [state, setState];
  }

  const handleFilterAndSearch = (activeUsers) => {
    // Make sure activeUsers is an array
    if (!Array.isArray(activeUsers)) {
      return;
    }
    const filteredWorkers = activeUsers.filter((d) => {
      // Check if the worker matches the selected belongTo option
      if (selectedBelongTo && d.belongTo !== selectedBelongTo) {
        return false;
      }

      // Check if the worker matches the selected insuranceType option
      if (selectedInsuranceType && d.insuranceType !== selectedInsuranceType) {
        return false;
      }

      if (
        selectedemploymentType &&
        d.employmentType !== selectedemploymentType
      ) {
        return false;
      }

      // Check if the worker matches the selected group option
      // Check if the worker matches the selected group option
      if (selectedGroup) {
        if (!d.userGroup || !d.userGroup.split(",").includes(selectedGroup)) {
          return false;
        }
      }

      if (
        selectedenrollmentType &&
        d.enrollmentType !== selectedenrollmentType
      ) {
        return false;
      }

      if (selectedworkScope && d.workScope !== selectedworkScope) {
        return false;
      }

      if (selectedworkerType && d.workerType !== selectedworkerType) {
        return false;
      }

      // If query_employmentStartAt is set, check only d.employmentStartAt field
      if (
        query_employmentStartAt &&
        d.employmentStartAt &&
        !d.employmentStartAt.includes(query_employmentStartAt)
      ) {
        return false;
      }

      if (
        query_employmentEndAt &&
        d.employmentEndAt &&
        !d.employmentEndAt.includes(query_employmentEndAt)
      ) {
        return false;
      }

      // If general query is set, check all fields
      if (query) {
        const targetedFields = Object.keys(d);
        const targetedValuesString = targetedFields
          .map((field) => {
            const fieldValue = d[field];
            // 필드 값이 숫자인 경우 문자열로 변환
            if (
              typeof fieldValue === "number" ||
              typeof fieldValue === "string"
            ) {
              return fieldValue.toString();
            }
            return "";
          })
          .join(" ")
          .toLowerCase();

        // 필드에 대해 부분 문자열 검색 수행
        if (!targetedValuesString.includes(query.toLowerCase())) {
          return false;
        }
      }

      return true;
    });

    setFilteredWorkers(filteredWorkers);
    setCurrentPage(0);
  };

  const handleSort = (column) => {
    // Only proceed if the clicked column is different from the current sort column
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortDirection("asc");
    } else {
      // If the same column is clicked again, reverse the sorting direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    }
    setCurrentPage(0);
  };

  const sortedWorkers = useMemo(() => {
    let sorted = [...filteredWorkers];

    if (sortColumn) {
      sorted = sorted.sort((a, b) => {
        // 비어있는 문자열 혹은 null 처리
        if (!a[sortColumn] || !b[sortColumn]) {
          return !a[sortColumn] ? -1 : 1;
        }

        // 문자열이나 숫자에 따라 비교 방법을 선택합니다.
        if (isNaN(a[sortColumn]) || isNaN(b[sortColumn])) {
          // 문자열 비교
          return a[sortColumn].localeCompare(b[sortColumn]);
        } else {
          // 숫자 비교
          const valueA = parseInt(a[sortColumn]);
          const valueB = parseInt(b[sortColumn]);

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        }
      });

      if (sortDirection === "desc") {
        sorted = sorted.reverse();
      }
    }

    return sorted;
  }, [filteredWorkers, sortColumn, sortDirection]);

  const init = async () => {
    const filterList = await getUserListFilterListService();

    // setBelongTofilterList(filterList.belongTo);
    setFilterLists(filterList);

    const catList = await getCategoryService();
    const activeCatList = catList.filter((cat) => cat.status !== "INACTIVE");
    setCateList(activeCatList);
    setTags((prevState) =>
      prevState.map((tags, index) => {
        const filteredCats = activeCatList.filter(
          (cat) => cat.categoryBigClassName === String(index)
        );
        return filteredCats.map((cat) => ({
          name: cat.categorySmallClassName,
          id: cat.id,
          status: cat.status,
        }));
      })
    );

    const users = await getUserListService(query);
    if (!users || !users.length) {
      console.error("init: users is undefined or empty:", users);
    }
    const activeUsers = users.filter((user) => user.status !== "INACTIVE");
    setuserList(activeUsers);

    //setFilteredWorkers(activeUsers); // 이 줄을 주석 처리하고,

    return activeUsers; // 이 줄을 추가합니다.
  };
  const downloadExcel = (csv) => {
    const worksheet = XLSX.utils.json_to_sheet(csv);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  useEffect(() => {
    init().then((activeUsers) => handleFilterAndSearch(activeUsers));
  }, [
    query_employmentStartAt,
    query_employmentEndAt,
    selectedGroup,
    selectedBelongTo,
    selectedInsuranceType,
    selectedworkerType,
    selectedworkScope,
    selectedenrollmentType,
    query,
  ]);

  useEffect(() => {
    handleFilterAndSearch(activeUsers);
  }, [
    query,
    query_employmentStartAt,
    query_employmentEndAt,
    selectedGroup,
    selectedBelongTo,
    selectedInsuranceType,
    selectedworkerType,
    selectedworkScope,
    selectedenrollmentType,
    currentPage,
  ]);

  const handleReset = () => {
    setGlobalFilter("");
    setQuery_employmentStartAt("");
    setQuery_employmentEndAt("");
    setSelectedGroup("");
    setSelectedBelongTo("");
    setSelectedInsuranceType("");
    setSelectedworkerType("");
    setSelectedworkScope("");
    setSelectedenrollmentType("");
    setCurrentPage(0);
  };
  useEffect(() => {
    // userList가 배열인지 확인
    if (Array.isArray(userList)) {
      // 초기 userList에서 필터링
      const filtered = userList.filter((item) => {
        // 각 행의 userGroup을 배열로 변환
        const userGroups = item.userGroup
          .split(",")
          .map((group) => group.trim());

        // 선택된 그룹이 userGroups 배열에 있는지 확인
        return userGroups.includes(selectedGroup);
      });

      // 필터링된 결과를 setFilteredWorkers에 반영
      setFilteredWorkers(filtered);
    }
  }, [selectedGroup, userList]); // selectedGroup 혹은 userList가 변경될 때마다 이 코드 블록이 실행됩니다.

  const handleCsv = async (admin) => {
    const CsvList = await getUserCsvService(admin);
    console.log("CsvList", CsvList);
    setCsvList(CsvList);
    let newList = [];
    for (let i = 0; i < CsvList.list.length; i++) {
      newList.push({
        id: CsvList.list[i].id,
        メールアドレス: CsvList.list[i].userEmail,
        ログインID: CsvList.list[i].userUniqueId,
        パスワード: CsvList.list[i].userPassword,
        隊員番号: CsvList.list[i].userNumber,
        裏番号: CsvList.list[i].userLastNumber,
        名前: CsvList.list[i].userName,
        フリガナ: CsvList.list[i].userNameHurigana,
        性別: CsvList.list[i].sex,
        生年月日: CsvList.list[i].userBirth,
        郵便番号: CsvList.list[i].postCode,
        住所01: CsvList.list[i].address01,
        住所02: CsvList.list[i].address02,
        住所03: CsvList.list[i].address03,
        電話番号: CsvList.list[i].userHousePhone,
        携帯電話: CsvList.list[i].userPhone,
        FAX番号: CsvList.list[i].userFaxNumber,
        所属: CsvList.list[i].belongTo,
        保険区分: CsvList.list[i].insuranceType,
        社員区分: CsvList.list[i].workerType,
        業務区分: CsvList.list[i].workScope,
        在籍区分: CsvList.list[i].enrollmentType,
        グループ: CsvList.list[i].userGroup,
        雇用期間_始: CsvList.list[i].employmentStartAt,
        雇用期間_終: CsvList.list[i].employmentEndAt,
        緊急連絡先関係: CsvList.list[i].emergencyContactRelation,
        緊急連絡先名前: CsvList.list[i].emergencyContactName,
        緊急連絡先住所: CsvList.list[i].emergencyContactAddress,
        緊急連絡先電話番号: CsvList.list[i].emergencyContactPhone,
        権限: CsvList.list[i].authority,
        メモ: CsvList.list[i].userMemo,
      });
    }
    downloadExcel(newList);
  };

  const handleButtonClick = (id) => {
    history.push(`/shinryu-userdetail?id=${id}`);
  };
  const handleButtonClick2 = (id) => {
    history.push(`/shinryu-userdetail?id=${id}&key=message`);
  };

  const itemsPerPage = 600000;
  const totalPages = Math.ceil(sortedWorkers.length / itemsPerPage);
  const pageCount = Math.ceil(sortedWorkers.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedWorkers.slice(startIndex, endIndex);
  const handlePagination = (newPage) => {
    if (newPage < 0) {
      return;
    }
    setCurrentPage(newPage);
  };
  const isPageNegative = startIndex < 0 || endIndex > sortedWorkers.length;
  return (
    <>
      <div className="custom-row">
        <PageTitle activeMenu="社員管理" motherMenu="shinryu" />
        <div className="custom-header">
          <div
            style={
              admin === "管理者C" || admin === "管理者B"
                ? { display: "none" }
                : { display: "block" }
            }>
            <Button
              className="btn btn-primary rounded btn-sm"
              onClick={() => handleCsv(csv)}>
              Excelダウンロード
            </Button>
          </div>
          <div
            style={
              admin == "管理者C" ? { display: "none" } : { display: "block" }
            }>
            <Link to="/shinryu-adduser">
              <Button className="btn btn-primary rounded btn-sm" type="submit">
                新規登録
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <div style={{ display: "block", width: "100%" }}>
                <div className="form-head d-flex mb-3 align-items-center ">
                  <select
                    value={selectedGroup}
                    onChange={(e) => setSelectedGroup(e.target.value)}
                    className={`form-control col-02 mr-3 ${
                      selectedGroup ? "" : "default-option"
                    }`}>
                    <option value="">グループ</option>
                    {catlist
                      .filter((cat) => cat.categoryBigClassName === "5") // filter by categoryBigClassName
                      .map((cat) => (
                        <option key={cat.id} value={cat.categorySmallClassName}>
                          {cat.categorySmallClassName}
                        </option>
                      ))}
                  </select>
                  <select
                    value={selectedBelongTo}
                    onChange={(e) => setSelectedBelongTo(e.target.value)}
                    className={`form-control col-02 mr-3 ${
                      selectedBelongTo ? "" : "default-option"
                    }`}>
                    <option value="">所属</option>
                    {catlist
                      .filter((cat) => cat.categoryBigClassName === "4")
                      .map((cat) => (
                        <option key={cat.id} value={cat.categorySmallClassName}>
                          {cat.categorySmallClassName}
                        </option>
                      ))}
                  </select>

                  <select
                    value={selectedInsuranceType}
                    onChange={(e) => setSelectedInsuranceType(e.target.value)}
                    className={`form-control col-02 mr-3 ${
                      selectedInsuranceType ? "" : "default-option"
                    }`}>
                    <option value="">保険区分</option>

                    {catlist
                      .filter((cat) => cat.categoryBigClassName === "6") // filter by categoryBigClassName
                      .map((cat) => (
                        <option key={cat.id} value={cat.categorySmallClassName}>
                          {cat.categorySmallClassName}
                        </option>
                      ))}
                  </select>

                  <select
                    value={selectedworkerType}
                    onChange={(e) => setSelectedworkerType(e.target.value)}
                    className={`form-control col-02 mr-3 ${
                      selectedworkerType ? "" : "default-option"
                    }`}>
                    <option value="">社員区分</option>

                    {catlist
                      .filter((cat) => cat.categoryBigClassName === "7") // filter by categoryBigClassName
                      .map((cat) => (
                        <option key={cat.id} value={cat.categorySmallClassName}>
                          {cat.categorySmallClassName}
                        </option>
                      ))}
                  </select>
                  <select
                    value={selectedworkScope}
                    onChange={(e) => setSelectedworkScope(e.target.value)}
                    className={`form-control col-02 mr-3 ${
                      selectedworkScope ? "" : "default-option"
                    }`}>
                    <option value="">業務区分</option>

                    {catlist
                      .filter((cat) => cat.categoryBigClassName === "8") // filter by categoryBigClassName
                      .map((cat) => (
                        <option key={cat.id} value={cat.categorySmallClassName}>
                          {cat.categorySmallClassName}
                        </option>
                      ))}
                  </select>
                  <select
                    value={selectedenrollmentType}
                    onChange={(e) => setSelectedenrollmentType(e.target.value)}
                    className={`form-control col-02 mr-3 ${
                      selectedenrollmentType ? "" : "default-option"
                    }`}>
                    <option value="">在籍区分</option>

                    {catlist
                      .filter((cat) => cat.categoryBigClassName === "9") // filter by categoryBigClassName
                      .map((cat) => (
                        <option key={cat.id} value={cat.categorySmallClassName}>
                          {cat.categorySmallClassName}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-head d-flex mb-2 align-items-center ">
                  <div className="input-group search-area rounded d-inline-flex mr-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="雇用期間開始日検索"
                      value={query_employmentStartAt}
                      onChange={(e) =>
                        setQuery_employmentStartAt(e.target.value)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleFilterAndSearch();
                        }
                      }}
                    />
                    <div
                      className="input-group-append"
                      onClick={handleFilterAndSearch}>
                      <span className="input-group-text">
                        <i className="flaticon-381-search-2" />
                      </span>
                    </div>
                  </div>
                  <div className="input-group search-area rounded d-inline-flex mr-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="雇用期間満了日検索"
                      value={query_employmentEndAt}
                      onChange={(e) => setQuery_employmentEndAt(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleFilterAndSearch();
                        }
                      }}
                    />
                    <div
                      className="input-group-append"
                      onClick={handleFilterAndSearch}>
                      <span className="input-group-text">
                        <i className="flaticon-381-search-2" />
                      </span>
                    </div>
                  </div>
                  <div className="input-group search-area rounded d-inline-flex  mr-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="キーワード検索"
                      value={globalFilter}
                      onInput={onSearchChange}
                    />
                    <div
                      className="input-group-append"
                      onClick={handleFilterAndSearch}>
                      <span className="input-group-text">
                        <i className="flaticon-381-search-2" />
                      </span>
                    </div>
                  </div>
                  <div className="input-group search-area rounded d-inline-flex ">
                    <Button
                      className="btn btn-light rounded btn-md"
                      onClick={() => handleReset()}>
                      フィルターリセット
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="tableFixHead2">
                <DataTable
                  value={currentItems}
                  paginator
                  rows={12000}
                  selectionMode="single"
                  dataKey="id"
                  removableSort
                  globalFilter={globalFilter}
                  onRowClick={(e) => handleButtonClick(e.data.userNumber)}>
                  <Column field="userNumber" header="隊員番号" sortable />
                  <Column field="userLastNumber" header="BNo." sortable />
                  <Column field="userName" header="名前" sortable />
                  <Column field="sex" header="性別" sortable />
                  <Column field="belongTo" header="所属" sortable />
                  <Column
                    field="userGroup"
                    header="グループ"
                    sortable
                    body={(rowData) => {
                      return (
                        <span className="ellipsis">
                          {rowData.userGroup
                            ? rowData.userGroup
                                .split(",")
                                .filter((value) => value.length > 1)
                                .join(" / ")
                            : ""}
                        </span>
                      );
                    }}
                  />
                  <Column field="insuranceType" header="保険区分" sortable />
                  <Column field="workerType" header="社員区分" sortable />
                  <Column field="workScope" header="業務区分" sortable />
                  <Column
                    field="employmentEndAt"
                    header="雇用期間"
                    sortable
                    body={(rowData) => "~" + rowData.employmentEndAt}
                  />
                  <Column field="enrollmentType" header="在籍区分" sortable />
                  <Column
                    header="メッセージ"
                    body={(rowData) => {
                      return (
                        <i
                          className="fa fa-regular fa-envelope font-18 align-middle mx-auto"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleButtonClick2(rowData.userNumber);
                          }}></i>
                      );
                    }}
                  />
                </DataTable>
              </div>
              <div className="dataTables_wrapper">
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    {startIndex + 1} ～{" "}
                    {Math.min(endIndex, sortedWorkers.length)} 件表示 （総
                    {sortedWorkers.length} 件）
                  </div>
                  {/* <div className="dataTables_paginate paging_simple_numbers">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate">
                      <Link
                        className={`paginate_button previous ${
                          currentPage === 0 ? "disabled" : ""
                        }`}
                        onClick={() => {
                          if (currentPage === 0) {
                            return;
                          }
                          setCurrentPage(currentPage - 1);
                        }}>
                        戻る
                      </Link>

                      {Array.from({ length: totalPages }).map((_, i) => (
                        <Link
                          key={i}
                          className={`paginate_button ${
                            currentPage === i ? "current" : ""
                          }`}
                          onClick={() => setCurrentPage(i)}>
                          {i + 1}
                        </Link>
                      ))}

                      <Link
                        className={`paginate_button next ${
                          currentPage === totalPages - 1 ? "disabled" : ""
                        }`}
                        onClick={() => {
                          if (currentPage === totalPages - 1) {
                            return;
                          }
                          setCurrentPage(currentPage + 1);
                        }}>
                        次へ
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div
                  style={
                    admin === "管理者C" || admin === "管理者B"
                      ? { display: "none" }
                      : { display: "block" }
                  }>
                  <Button
                    className="btn btn-primary rounded btn-sm"
                    onClick={() => handleCsv(csv)}>
                    Excelダウンロード
                  </Button>
                </div>
                <div
                  style={
                    admin == "管理者C"
                      ? { display: "none" }
                      : { display: "block" }
                  }>
                  <Link to="/shinryu-adduser">
                    <Button
                      className="btn btn-primary rounded btn-sm"
                      type="submit">
                      新規登録
                    </Button>
                  </Link>
                </div>
              </div>
            </Card.Body>
          </Card>
          {/* <!-- /# card --> */}
        </Col>
      </Row>
    </>
  );
};
export default UserList;
